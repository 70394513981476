const EN = {
  name: "English",
  text: [],
};
const PT = {
  name: "Portuguese",
  text: [],
  countryCodes: [
    "BR",
    "BRA",
    "AO",
    "AGO",
    "MZ",
    "MOZ",
    "PT",
    "PRT",
    "GW",
    "GNB",
    "TL",
    "TLS",
    "GQ",
    "GNQ",
    "MO",
    "MAC",
    "CV",
    "CPV",
    "ST",
    "STP",
  ],
};

export const translateMap = { EN, PT };
