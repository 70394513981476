import { useState } from "react";
import { Button } from "react-bootstrap";
import { globalFetch } from "../../utils";

export const CoinbaseCheckoutForm = ({ userData }) => {
  const [hostedUrl, setHostedUrl] = useState(null);

  const doConfirmPayment = async () => {
    try {
      const { email, firstName, lastName, lastIpAddress } = userData;

      const { data } = await globalFetch("/api/coinbase/pay", "POST", {
        email,
        firstName,
        lastName,
        lastIpAddress,
      });
      if (data.hosted_url) {
        setHostedUrl(data.hosted_url);
        window.location.href = data.hosted_url;
        return;
      } else {
        throw Error("missing host url from coinbase data");
      }
    } catch (e) {
      console.log(`Coinbase Payment error  - ${e}`);
      return;
    }
  };

  return (
    <div className="text-center">
      <div className="m-5">
        Please note that payments using crypto currencies could take up to 1
        hour to settle on the blockchain.
      </div>
      <Button onClick={doConfirmPayment}>Pay With Crypto</Button>
    </div>
  );
};
