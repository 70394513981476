import { LangContext } from "../../context";
import React, { useContext } from "react";

export const DisplayLocalText = ({ data, targetDataKey }) => {
  const selectedLang = useContext(LangContext);
  const displayText = () => {
    if (
      selectedLang !== "EN" &&
      data.hasOwnProperty(`${targetDataKey}${selectedLang}`)
    ) {
      return data[`${targetDataKey}${selectedLang}`];
    }
    return data[targetDataKey];
  };

  return <span>{displayText()}</span>;
};
