import { Row, Container, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DisplayLocalText } from "..";
import "./style.css";

export const PageTitle = ({ prevTitle, prevLink, title = "", data = null }) => {
  return (
    <Container fluid id="pageTitle-comp">
      <Row>
        <Col style={{ display: "flex", alignItems: "center" }}>
          <Link className="noStyleLink" id="prevTitle" to={prevLink}>
            <h3 style={{ marginRight: "15px" }}>{prevTitle && prevTitle}</h3>
          </Link>
          <h3 id="pageTitle">
            {data ? (
              <DisplayLocalText data={data} targetDataKey={"title"} />
            ) : (
              title
            )}
          </h3>
        </Col>
      </Row>
    </Container>
  );
};
