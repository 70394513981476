import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { globalFetch } from "../utils";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export const CheckoutForm = ({ userData, doUpdateUser }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitSub = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement("card"),
    });

    if (result.error) {
      console.log("Error with stripe");
      console.log(result.error);
      alert("Error with payment");
      setIsLoading(false);
      return;
    } else {
      const { data } = await globalFetch("/api/stripe/sub", "POST", {
        payment_method: result.paymentMethod.id,
        email: userData.email,
        name: `${userData.firstName} ${userData.lastName}`,
      });

      const { client_secret, status } = data;

      if (status === "requires_action") {
        const confirm = await stripe.confirmCardPayment(client_secret);
        if (confirm.error) {
          console.log("Error with stripe");
          setMessage(confirm.error.message);
          console.log(confirm.error);
          setIsLoading(false);
          return;
        } else {
          //success
          doUpdateUser();
          setIsLoading(false);
        }
      } else {
        //success
        doUpdateUser();
        setIsLoading(false);
      }
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmitSub}>
      <CardElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};
