import publicIp from "public-ip";
import { globalFetch } from ".";

export const createLog = async (userData, method, message) => {
  const ipAddress = await publicIp.v4();
  try {
    return await globalFetch("/api/user-log", "POST", {
      userData,
      method,
      message,
      ipAddress,
    });
  } catch (e) {
    const { data } = e.response;
    console.log(data.message);
    return;
  }
};
