import { useState, useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { globalFetch } from "../../utils";
import "./style.css";

export const AfroverseCalendar = () => {
  const [eventFilter, setEventFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState("current");
  const [fetching, setFetching] = useState(false);
  const [events, setEvents] = useState([]);

  const doFetchCalenderContent = async () => {
    setEvents([]);
    document.getElementById("event-container").scrollTop = 0;
    let param = "";
    setFetching(true);
    if (eventFilter !== "all") {
      param = `/${eventFilter}`;
    }
    try {
      const { data } = await globalFetch(
        `/api/afroverseCalendar${param}/${dateFilter}`
      );
      setEvents(data);
      console.log(data);
    } catch (e) {
      alert("error fetching events");
      console.log(e);
    }
    setFetching(false);
  };

  const doSetEventFilter = (eventType) => setEventFilter(eventType);

  const doSetDateFilter = (dateType) => setDateFilter(dateType);

  const getDayOfWeek = (date) => {
    const dayOfWeek = new Date(date.replace(/T.*/g, "")).getUTCDay();
    return isNaN(dayOfWeek)
      ? null
      : [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ][dayOfWeek];
  };

  const formatDate = (dateString) => {
    dateString = dateString.replace(/T.*/g, "");

    let [year, month, day] = dateString.split("-");

    return `${parseInt(month)}/${parseInt(day)}/${year}`;
  };

  useEffect(() => {
    doFetchCalenderContent();
  }, [eventFilter, dateFilter]);

  return (
    <Container fluid id="afroverseCalendar-page-programs">
      <Row id="filters">
        <Col xs="6">
          <div id="event-type-filter" className="text-center">
            <Button
              onClick={() => {
                doSetEventFilter("drop");
              }}
              disabled={fetching}
              className={`${
                eventFilter === "drop" ? "selected" : "not-selected"
              } m-2`}
            >
              Drops
            </Button>

            <Button
              onClick={() => {
                doSetEventFilter("ed");
              }}
              disabled={fetching}
              className={`${
                eventFilter === "ed" ? "selected" : "not-selected"
              } btn m-2`}
            >
              Education
            </Button>

            <Button
              onClick={() => {
                doSetEventFilter("all");
              }}
              disabled={fetching}
              className={`${
                eventFilter === "all" ? "selected" : "not-selected"
              } m-2`}
            >
              All
            </Button>
          </div>
        </Col>
        <Col xs="6">
          <div className="text-center">
            <Button
              onClick={() => {
                doSetDateFilter("current");
              }}
              className={`${
                dateFilter === "current" ? "selected" : "not-selected"
              } m-2`}
              disabled={fetching}
            >
              Current
            </Button>

            <Button
              onClick={() => {
                doSetDateFilter("future");
              }}
              className={`${
                dateFilter === "future" ? "selected" : "not-selected"
              } m-2`}
              disabled={fetching}
            >
              Future
            </Button>
          </div>
        </Col>
      </Row>
      <Row id="event-container" style={{ flex: 1, overflowY: "scroll" }}>
        <Col xs="12" style={{ padding: 0, paddingBottom: 250 }}>
          <div>
            {events?.map((event) => (
              <div className="event">
                <span
                  className="event-date"
                  style={{ margin: "0px", padding: 0 }}
                >
                  {`${getDayOfWeek(event.date)} ${formatDate(event.date)}`}
                </span>
                <h1 style={{ margin: "0px", padding: 0 }}>
                  <a className="event-title" href={event.url} target="_blank">
                    {event.title}
                  </a>{" "}
                </h1>
              </div>
            ))}
            {events.length === 0 && fetching === false && (
              <p className="text-center">Nothing to see here...</p>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
