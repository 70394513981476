import { PortableText as SanityPortableText } from "@portabletext/react";
import urlBuilder from "@sanity/image-url";
import { getImageDimensions } from "@sanity/asset-utils";

export default function PortableText({ value }) {
  return (
    <SanityPortableText
      value={value}
      components={{
        types: {
          image: Image,
          youtube: YouTube,
          vimeo: Vimeo,
        },
      }}
    />
  );
}

function Image({ value }) {
  const { width, height } = getImageDimensions(value);
  const component = (
    <figure>
      <img
        src={urlBuilder()
          .projectId("jcvgjqzy")
          .dataset("blog_data")
          .image(value)
          .width(1_000)
          .fit("max")
          .auto("format")
          .url()}
        alt={value.alt || " "}
        loading="lazy"
        style={{
          // Avoid image layout jumping around
          aspectRatio: `${width} / ${height}`,
        }}
      />
    </figure>
  );

  if (value.link) {
    return (
      <a
        href={value.link}
        target="_blank"
        rel="noreferrer"
        className="block hover:opacity-75 transition-opacity"
      >
        {component}
      </a>
    );
  }

  return component;
}

function YouTube({ value }) {
  const url = new URL(value.url);
  return (
    <figure>
      <iframe
        src={`https://www.youtube.com/embed/${url.searchParams.get("v")}`}
        className="w-full aspect-[16/9]"
      />
    </figure>
  );
}

function Vimeo({ value }) {
  const url = new URL(value.url);
  return (
    <figure>
      <iframe
        src={`https://player.vimeo.com/video${url.pathname}`}
        className="w-full aspect-[16/9]"
      />
    </figure>
  );
}
