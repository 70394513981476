import Cookies from "universal-cookie";
const cookies = new Cookies();

export const getCookie = async (key) => {
  return await cookies.get(key);
};

export const setCookie = async (key, value) => {
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);
  await cookies.set(key, value, { path: "/", expires: nextYear });
  console.log(`${key} set in cookies ${value}`);
};

export const deleteCookie = async (key, value) => {
  await cookies.remove(key);
  console.log(`${key} removed in cookies ${value}`);
};
