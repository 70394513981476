import { getIp } from "./getIp";
import { globalFetch } from "./globalFetch";

export const getIpLocation = async () => {
  const ip = await getIp();
  // const ip = "181.215.0.49"; brazilian ip
  try {
    const { data } = await globalFetch(
      `https://geolocation-db.com/json/037714e0-ec1b-11eb-9e6f-31f179bdc488/${ip}`
    );
    return data;
  } catch (e) {
    console.log(`goalocation api failed - ${e}`);
    return {
      IPv4: "99.73.228.227",
      city: "Austin",
      country_code: "US",
      country_name: "United States",
      latitude: 30.1743,
      longitude: -97.8225,
      postal: "78748",
      state: "Texas",
    };
  }
};
