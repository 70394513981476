import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { setCookie } from "../../utils";

export const LoginButton = ({
  isSignUp = false,
  classToAdd = "light",
  altText,
  style,
  imageUrl = null,
}) => {
  const { loginWithRedirect } = useAuth0();
  const [isNewAccount] = useState(isSignUp);

  const doLogin = async () => {
    let options = {};

    if (isNewAccount) {
      options = {
        ...options,
        screen_hint: "signup",
        redirect_uri: "",
        appState: {
          returnTo: "",
        },
      };
    } else {
      options = {
        ...options,
        redirect_uri: "",
        appState: {
          returnTo: "",
        },
      };
    }

    setCookie(
      "exlorations-redirect",
      isNewAccount ? "/signup/registration" : "/programs"
    );
    await loginWithRedirect(options);
  };

  const StyledBtn = (text) => (
    <Button
      onClick={() => doLogin()}
      id="login-btn"
      className={classToAdd}
      style={style}
    >
      {altText ? altText : text}
    </Button>
  );

  return isSignUp
    ? imageUrl && (
        <Button
          onClick={() => doLogin()}
          id={"login-btn-image"}
          style={{
            backgroundColor: "transparent",
            borderStyle: "none",
            borderColor: "none",
          }}
        >
          <img src={imageUrl} style={{ width: "100%" }} />
        </Button>
      )
    : StyledBtn("Log In");
};
