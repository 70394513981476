import { useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Dropdown,
  DropdownButton,
  Button,
  Alert,
} from "react-bootstrap";
import { useHistory } from "react-router";

import { Link } from "react-router-dom";

import { globalFetch } from "../../utils";
import "./style.css";

export const ContactUs = () => {
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");

  const [selectedIssue, setSelectedIssue] = useState({
    type: "DEFAULT",
    displayName: "Select Category",
  });

  const [message, setMessage] = useState("");

  const [disabled, setDisabled] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const history = useHistory();

  const issues = [
    {
      type: "CANCEL_SUBSCRIPTION",
      displayName: "Cancel my subscription",
    },
    {
      type: "LOGGIN_PWD_RESET",
      displayName: "Logging in / resetting my password",
    },
    {
      type: "PAYMENT_ISSUE",
      displayName: "Payment issues",
    },
    {
      type: "QUESTION_ABOUT_CONTENT",
      displayName: "Question about content",
    },
    {
      type: "COPYRIGHT_CLAIM",
      displayName: "Copyright claim content",
    },
    {
      type: "CHANGE_EMAIL",
      displayName: "Change my email address",
    },
    {
      type: "PRIVACY",
      displayName: "Privacy",
    },
    {
      type: "OTHER",
      displayName: "Other",
    },
  ];

  const doSendEmail = async () => {
    if (name && email && selectedIssue.type !== "DEFAULT" && message) {
      setDisabled(true);
      try {
        await globalFetch("/api/email/help", "POST", {
          name,
          email,
          selectedIssue: selectedIssue.type,
          message,
        });
        doShowSuccessMessage();
      } catch (e) {
        alert("error sending message");
        console.log(e);
      }
      setDisabled(false);
    }
  };

  const doShowSuccessMessage = async () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 2000);
    setTimeout(() => {
      history.push("/");
    }, 2500);
  };

  //TODO
  /*
  create function that displays pop up saying "Thank you for your message!" then redirects them to the homepage after 2 secs

  */

  return (
    <>
      <Container fluid id="coming-soon-page">
        <div
          style={{
            width: "100%",
            position: "relative",
            top: "150px",
            display: "flex",
            justifyContent: "center",
            visibility: `${showSuccessMessage ? "visible" : "hidden"}`,
          }}
        >
          <Alert
            variant={"success"}
            style={{
              width: "50%",
              textAlign: "center",
              backgroundColor: "rgb(255, 179, 170)",
              borderColor: "rgb(255, 179, 170)",
              color: "black",
            }}
          >
            Thank you for your message. We'll get back to you soon.
          </Alert>
        </div>

        <Row
          id="coming-soon-page-section-1"
          className="coming-soon-page-section"
        >
          <Col id="coming-soon-page-section-1-text-container">
            {/* <h3 className="coming-soon-page-large-text">Need Help?</h3> */}
            <p className="coming-soon-page-large-text text-center">
              Need Help?
            </p>
            <div id="coming-soon-page-hero-btn-container">
              <a
                style={{ visibility: "none" }}
                id="link-to-faq"
                href="/#faq-sec"
              >
                <p style={{ color: "salmon", textAlign: "center" }}>
                  Read FAQs
                </p>
              </a>

              <p style={{ color: "white", textAlign: "center" }}>
                Fill out the form below. The more info you provide, the better
                we can help you!
              </p>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <Form.Group controlId="firstName" style={{ width: "80%" }}>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    disabled={disabled}
                    required
                    defaultValue={name}
                  />
                </Form.Group>
              </div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <Form.Group controlId="email" style={{ width: "80%" }}>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Email Address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    disabled={disabled}
                    required
                    defaultValue={email}
                  />
                </Form.Group>
              </div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <Form.Group style={{ width: "80%" }}>
                  <DropdownButton
                    id="coming-soon-dropdown-menu-align-end-dark"
                    title={selectedIssue.displayName}
                    style={{ width: "100%" }}
                  >
                    {[
                      {
                        type: "DEFAULT",
                        displayName: "Select Category",
                        disabled: true,
                      },
                      ...issues,
                    ].map((issue, idx) => (
                      <Dropdown.Item
                        size="lg"
                        style={{ width: "100%" }}
                        eventKey="1"
                        onClick={(e) => {
                          setSelectedIssue(issue);
                        }}
                        disabled={issue.disabled}
                        id={issue}
                        key={`idx-${idx}`}
                      >
                        {issue.displayName}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <Form.Group style={{ width: "80%" }}>
                  <Form.Control
                    size="lg"
                    as="textarea"
                    rows={5}
                    defaultValue={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    placeholder={"Write your message here"}
                  />
                </Form.Group>
              </div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <Form.Group style={{ width: "80%" }}>
                  <Button
                    className="coming-soon-btn light"
                    style={{ width: "100%" }}
                    disabled={disabled}
                    variant="primary"
                    onClick={doSendEmail}
                  >
                    Send
                  </Button>
                </Form.Group>
              </div>
              {/* 
              // TODO uncomment when video is ready
              <FontAwesomeIcon
                size={"xs"}
                icon={faPlay}
                style={{
                  backgroundColor: "black",
                  width: "50px",
                  height: "50px",
                  padding: "10px 20px 10px 20px",
                  borderRadius: 100,
                  cursor: "pointer",
                  color: "rgb(63, 237, 244)",
                  boxShadow:
                    " 2px 2px 12px 0 rgba(128, 128, 128, 0.63), -2px -2px 12px 0 rgba(155, 155, 155, 0.3)",
                }}
                className="FAQ-header-icon"
                onClick={() => {
                  setDoShowYoutubeModal(true);
                }}
              /> */}
            </div>
          </Col>
        </Row>
      </Container>
      {/* pre-page load fade in comp  AppCover() */}
    </>
  );
};
