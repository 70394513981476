import { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { getIp } from "../../../utils";
import { globalFetch } from "../../utils";
import "./style.css";

export const SetCategoriesModal = ({
  doShow = false,
  selectedCategories,
  userContext,
  MinCategories,
}) => {
  const [show, setShow] = useState(doShow);
  const handleClose = () => setShow(false);
  const [categories, setCategories] = useState([]);
  const [currentCategories, setCurrentCategories] =
    useState(selectedCategories);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAndSetCategories = async () => {
    setIsLoading(true);
    const { data } = await globalFetch("/api/category");
    await setCategories(data);
    await setIsLoading(false);
  };

  const doUpdateUsersCategories = async () => {
    try {
      setIsLoading(true);
      await patchUser(userContext.userData, {
        preferredCategories: currentCategories,
      });
      setIsLoading(false);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const patchUser = async (user, body) => {
    const ipAddress = await getIp();
    body = { ...body, lastIpAddress: ipAddress };
    try {
      await globalFetch(`/api/user/${user.email}`, "PATCH", body);

      const newUserData = { ...userContext.userData, ...body };
      userContext.setUserData(newUserData);
    } catch (e) {
      alert("Error updating user");
      return;
    }
  };

  const doSetSelectedCategories = (category) => {
    const updatedCat = [...currentCategories];
    const index = updatedCat.indexOf(category._id);
    if (index > -1) {
      updatedCat.splice(index, 1);
    } else {
      updatedCat.push(category._id);
    }
    setCurrentCategories(updatedCat);
  };

  useEffect(() => {
    setShow(doShow);
    if (doShow === true) {
      fetchAndSetCategories();
    }
  }, [doShow]);

  useEffect(() => {
    setCurrentCategories(selectedCategories);
  }, [selectedCategories]);

  return (
    <Modal show={show} backdrop="static" size="lg">
      {/* <Modal.Header>
        <Modal.Title>Select your </Modal.Title>
      </Modal.Header> */}
      <Modal.Body style={{ backgroundColor: "white" }}>
        <div id="category-title-container">
          <h1 id="category-title"> Select your favorite categories</h1>
          <p id="category-sub-title">
            Choose at least 3 categories of your interest below. These
            categories help us with catered content and recomendations
          </p>
        </div>
        <div id="category-list-container">
          {categories.map((cat) => (
            <div
              className="category-item-container"
              onClick={() => {
                doSetSelectedCategories(cat);
              }}
            >
              <Form.Check
                type="checkbox"
                className="mb-2 d-flex align-items-center"
                label={cat.title}
                checked={currentCategories.includes(cat._id)}
                onClick={() => {
                  doSetSelectedCategories(cat);
                }}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "white" }}>
        <Button
          disabled={currentCategories.length < MinCategories}
          variant="primary"
          onClick={doUpdateUsersCategories}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
