import MoonLoader from "react-spinners/ClipLoader";
import styled from "styled-components";
import PropTypes from "prop-types";

const Loader1Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader1Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

export const LoaderSmall = ({ text }) => {
  return (
    <Loader1Container>
      <Loader1Content>
        <MoonLoader />
        <p style={{ marginTop: 50, fontSize: "1.644rem" }}>{text}</p>
      </Loader1Content>
    </Loader1Container>
  );
};

LoaderSmall.propTypes = {
  text: PropTypes.string,
};
