import { useEffect, useState } from "react";
import { Navbar, Container, Button } from "react-bootstrap";
import { LangSelect, LoginButton, SettingsModal } from "..";
import { useLocation } from "react-router-dom";
import "./style.css";
import { faBars, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth0 } from "@auth0/auth0-react";
import { deleteCookie } from "../../utils";

const LogoutButton = ({ classToAdd = "light" }) => {
  const { logout } = useAuth0();
  const redirectUri =
    process.env.isDev === true || "true"
      ? "https://www.mrwelltravelled.com/"
      : "https://www.mrwelltravelled.com/";
  const handleLogout = async () => {
    await deleteCookie("exlorations-redirect");
    logout({ returnTo: redirectUri });
  };
  return (
    <Button
      className={classToAdd}
      id="logout-btn"
      onClick={() => {
        handleLogout();
      }}
    >
      Log Out
    </Button>
  );
};

export const Navigation = ({ handleLangChange, selectedLang }) => {
  const [showAltNav, setShowAltNav] = useState(false);
  const [doShowSettingModal, setDoShowSettingModal] = useState(false);
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const doShowLogin =
    !isAuthenticated &&
    (location.pathname === "/" ||
      location.pathname === "/contact-us" ||
      location.pathname.startsWith("/magazine"));

  const isLightTheme = false;

  const showHomeButton = location.pathname.startsWith("/magazine");

  const doDisplayNav = () => {
    let scrolled = document.scrollingElement.scrollTop;
    if (scrolled > 70) {
      setShowAltNav(true);
    } else {
      setShowAltNav(false);
    }
  };

  const doCloseNav = () => {
    //TODO REMOVE  mainNavToggle and altNavToggle gaurd when toggle reintroduced
    if (
      document.getElementById("mainNavToggle") &&
      !document.getElementById("mainNavToggle").classList.contains("collapsed")
    ) {
      document.getElementById("mainNavToggle").click();
    }
    if (
      document.getElementById("altNavToggle") &&
      !document.getElementById("altNavToggle").classList.contains("collapsed")
    ) {
      document.getElementById("altNavToggle").click();
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      doDisplayNav();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    doCloseNav();
  }, [location]);

  const comingSoon = (
    <>
      <Navbar
        expand="lg"
        variant="light"
        id="mainNav"
        className={isOpen && "openMainNav"}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              id="mainNavLogo"
              src={process.env.PUBLIC_URL + "/images/terq.png"}
            />
          </Navbar.Brand>
          {/* //TODO REMOVE mainNavToggle and altNavToggle gaurd when toggle
      reintroduced */}
          {location.pathname === "/afroverseCalendar" && (
            <div>
              <img
                id="afroLogo"
                src={process.env.PUBLIC_URL + "/images/calendarImage.png"}
              />
            </div>
          )}
        </Container>
      </Navbar>
      <Navbar
        expand="lg"
        variant="light"
        bg="success"
        id="altNav"
        className={`${showAltNav ? "showAltNav" : ""}`}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              id="mainNavLogo"
              style={{ marginTop: 0 }}
              src={process.env.PUBLIC_URL + "/images/mrwelltravelledLogo.png"}
            />
          </Navbar.Brand>

          {/* //TODO REMOVE  mainNavToggle and altNavToggle gaurd when toggle reintroduced */}
          {location.pathname === "/afroverseCalendar" && (
            <div>
              <img
                id="afroLogo"
                src={process.env.PUBLIC_URL + "/images/calendarImage.png"}
              />
            </div>
          )}
        </Container>
      </Navbar>
      <SettingsModal
        doShow={doShowSettingModal}
        setShow={setDoShowSettingModal}
      />
    </>
  );

  const live = (
    <>
      <Navbar
        expand="lg"
        variant="light"
        id="mainNav"
        className={isOpen && "openMainNav"}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <div className="flex items-end gap-x-6">
              <img
                id="mainNavLogo"
                style={{ filter: isLightTheme ? "invert(1)" : "" }}
                src={process.env.PUBLIC_URL + "/images/mrwelltravelledLogo.png"}
              />
              {showHomeButton && (
                <a
                  href="/"
                  className="block text-base text-black px-2.5 py-1.5 bg-gradient-to-br from-cyan-200 to-cyan-400 rounded-xl outline outline-cyan-300 hover:text-black hover:no-underline"
                >
                  Home
                </a>
              )}
            </div>
          </Navbar.Brand>
          {/* //TODO REMOVE mainNavToggle and altNavToggle gaurd when toggle
      reintroduced */}
          {location.pathname === "/afroverseCalendar" && (
            <div>
              <img
                id="afroLogo"
                src={process.env.PUBLIC_URL + "/images/calendarImage.png"}
              />
            </div>
          )}

          {location.pathname !== "/afroverseCalendar" && (
            <>
              <Navbar.Toggle
                id="mainNavToggle"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <FontAwesomeIcon size={"lg"} icon={faBars} id="navIcon" />
              </Navbar.Toggle>
              <Navbar.Collapse className="justify-content-end">
                <div id="NavDropDownItemContainer">
                  {doShowLogin && (
                    <div className="navDropDownItem">
                      <LoginButton />
                    </div>
                  )}
                  {isAuthenticated && (
                    <div className="navDropDownItem">
                      <Button
                        variant="link"
                        onClick={() => {
                          setDoShowSettingModal(true);
                        }}
                        id="cog-btn"
                        className="light"
                      >
                        <FontAwesomeIcon
                          size={"lg"}
                          style={{
                            color: "black",
                            fontWeight: 100,
                          }}
                          icon={faCog}
                        />
                      </Button>
                    </div>
                  )}

                  <div className="navDropDownItem">
                    <LangSelect
                      selectedLang={selectedLang}
                      setSelectedLang={handleLangChange}
                      classToAdd={"light"}
                    />
                  </div>
                  {isAuthenticated && (
                    <div className="navDropDownItem">
                      <LogoutButton />
                    </div>
                  )}
                </div>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
      <Navbar
        expand="lg"
        variant="light"
        bg="success"
        id="altNav"
        className={`${showAltNav ? "showAltNav" : ""}`}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <div className="flex items-end gap-x-6">
              <img
                id="mainNavLogo"
                style={{ marginTop: 0 }}
                src={process.env.PUBLIC_URL + "/images/mrwelltravelledLogo.png"}
              />
              {showHomeButton && (
                <a
                  href="/"
                  className="block text-base text-black px-2.5 py-1.5 bg-gradient-to-br from-red-200 to-red-400 rounded-xl outline outline-red-300 hover:text-black hover:no-underline"
                >
                  Home
                </a>
              )}
            </div>
          </Navbar.Brand>

          {/* //TODO REMOVE  mainNavToggle and altNavToggle gaurd when toggle reintroduced */}
          {location.pathname === "/afroverseCalendar" && (
            <div>
              <img
                id="afroLogo"
                src={process.env.PUBLIC_URL + "/images/calendarImage.png"}
              />
            </div>
          )}
          {location.pathname !== "/afroverseCalendar" && (
            <>
              <Navbar.Toggle
                id="mainNavToggle"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <FontAwesomeIcon size={"lg"} icon={faBars} id="navIcon" />
              </Navbar.Toggle>
              <Navbar.Collapse className="justify-content-end">
                <div id="NavDropDownItemContainer">
                  {doShowLogin && (
                    <div className="navDropDownItem">
                      <LoginButton classToAdd="dark" />
                    </div>
                  )}
                  {isAuthenticated && (
                    <div className="navDropDownItem">
                      <Button
                        variant="link"
                        onClick={() => {
                          setDoShowSettingModal(true);
                        }}
                        id="cog-btn"
                        className="light"
                      >
                        <FontAwesomeIcon
                          size={"lg"}
                          style={{
                            color: "black",
                            fontWeight: 100,
                          }}
                          icon={faCog}
                        />
                      </Button>
                    </div>
                  )}

                  <div className="navDropDownItem">
                    <LangSelect
                      selectedLang={selectedLang}
                      setSelectedLang={handleLangChange}
                      classToAdd={"light"}
                    />
                  </div>
                  {isAuthenticated && (
                    <div className="navDropDownItem">
                      <LogoutButton />
                    </div>
                  )}
                </div>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
      <SettingsModal
        doShow={doShowSettingModal}
        setShow={setDoShowSettingModal}
      />
    </>
  );
  return live;
};
