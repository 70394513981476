import { useContext, useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { UserContext } from "../../../context";
import { getIp, globalFetch } from "../../../utils";
import "./style.css";

const UserEdit = ({ userData, patchUser, handleBack }) => {
  const [firstName, setFirstName] = useState(userData?.firstName);
  const [lastName, setLastName] = useState(userData?.lastName);
  const [username, setUsername] = useState(userData?.username);
  const [email, setEmail] = useState(userData?.email);
  const [disabled, setDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const doUpdateUser = async () => {
    if (firstName && lastName) {
      setDisable(true);
      try {
        await patchUser(userData, {
          firstName,
          lastName,
          username,
        });
        setTimeout(() => {
          setDisable(false);
        }, 1000);
      } catch (e) {
        alert("Error updating name");
        setDisable(false);
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setFirstName(userData?.firstName);
      setLastName(userData?.lastName);
      setUsername(userData?.username);
      setEmail(userData?.email);
    }
  }, [userData]);

  return (
    <div>
      <Form>
        <div id="UserEditHeaderContainer">
          <p id="UserEditHeader">User Information</p>
        </div>
        <Form.Group controlId="error">
          <Form.Text className="text-danger text-center">
            {errorMessage}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="firstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="First Name"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            disabled={disabled}
            required
            defaultValue={firstName}
          />
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Last Name"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            disabled={disabled}
            required
            defaultValue={lastName}
          />
        </Form.Group>
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Username"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            disabled={disabled}
            required
            defaultValue={username}
          />
        </Form.Group>
        <Form.Group controlId="email">
          <pre>{email}</pre>
        </Form.Group>
        <Form.Group style={{ display: "flex", flexDirection: "row-reverse" }}>
          <div className="m-1">
            <Button
              className="settingsItemBtn"
              onClick={doUpdateUser}
              disabled={disabled}
            >
              Save
            </Button>
          </div>
          <div className="m-1">
            <Button
              className="settingsItemBtnDull"
              onClick={handleBack}
              disabled={disabled}
            >
              Back
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  );
};

const CategoryEdit = ({ userData, patchUser, handleBack }) => {
  const [categories, setCategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState(
    userData?.preferredCategories
  );
  const [isLoading, setIsLoading] = useState(true);

  const fetchAndSetCategories = async () => {
    setIsLoading(true);
    const { data } = await globalFetch("/api/category");
    await setCategories(data);
    await setIsLoading(false);
  };

  const doUpdateUsersCategories = async () => {
    try {
      setIsLoading(true);
      await patchUser(userData, {
        preferredCategories: currentCategories,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const doSetSelectedCategories = (category) => {
    const updatedCat = [...currentCategories];
    const index = updatedCat.indexOf(category._id);
    if (index > -1) {
      updatedCat.splice(index, 1);
    } else {
      updatedCat.push(category._id);
    }
    setCurrentCategories(updatedCat);
  };

  useEffect(() => {
    fetchAndSetCategories();
  }, []);

  return (
    <div id="category-title-container">
      <Form>
        <h1 id="category-title"> Select your favorite categories</h1>
        <p id="category-sub-title">
          Choose at least 3 categories of your interest below. These categories
          help us with catered content and recomendations.
        </p>
        <div
          id="category-list-container"
          style={{
            height: 200,
            overflow: "scroll",
            backgroundColor: "#eeee",
            padding: 10,
            marginBottom: 10,
            borderRadius: 9,
          }}
        >
          {categories.map((cat) => (
            <div
              className="category-item-container"
              onClick={() => {
                doSetSelectedCategories(cat);
              }}
            >
              <Form.Check
                type="checkbox"
                className="mb-2 d-flex align-items-center"
                label={cat.title}
                checked={currentCategories.includes(cat._id)}
                onClick={() => {
                  doSetSelectedCategories(cat);
                }}
              />
            </div>
          ))}
        </div>
        <Form.Group style={{ display: "flex", flexDirection: "row-reverse" }}>
          <div className="m-1">
            <Button
              onClick={doUpdateUsersCategories}
              disabled={
                !currentCategories || currentCategories.length < 3 || isLoading
              }
              className="settingsItemBtn"
            >
              Save
            </Button>
          </div>
          <div className="m-1">
            <Button className="settingsItemBtnDull" onClick={handleBack}>
              Back
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  );
};

export const SettingsModal = ({ doShow = false, setShow }) => {
  const [contentToShow, setContentToShow] = useState(null);
  const userContext = useContext(UserContext);

  const doSetContentToShow = (type) => {
    setContentToShow(type);
  };

  const patchUser = async (user, body) => {
    const ipAddress = await getIp();
    body = { ...body, lastIpAddress: ipAddress };
    try {
      await globalFetch(`/api/user/${user.email}`, "PATCH", body);

      const newUserData = { ...userContext.userData, ...body };
      userContext.setUserData(newUserData);
    } catch (e) {
      alert("Error updating user");
      return;
    }
  };

  const displayEdit = () => {
    if (contentToShow === "user") {
      return (
        <UserEdit
          userData={userContext?.userData}
          patchUser={patchUser}
          handleBack={() => {
            doSetContentToShow(null);
          }}
        />
      );
    }

    if (contentToShow === "categories") {
      return (
        <CategoryEdit
          userData={userContext?.userData}
          patchUser={patchUser}
          handleBack={() => {
            doSetContentToShow(null);
          }}
        />
      );
    }

    return null;
  };

  const SettingTypeChoice = () => {
    return (
      <div id="settingsItems-container">
        <div className="settingsItem">
          <Button
            className="settingsItemBtn lg"
            onClick={() => {
              setContentToShow("user");
            }}
          >
            User Account
          </Button>
        </div>
        <div className="settingsItem">
          <Button
            className="settingsItemBtn lg"
            onClick={() => {
              setContentToShow("categories");
            }}
          >
            Preferred Categories
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        show={doShow}
        backdrop="static"
        size="md"
        onHide={() => {
          setContentToShow(null);
          setShow(false);
        }}
        style={{ marginTop: 100 }}
      >
        <div id="SettingsModal" style={{ backgroundColor: "white" }}>
          <Modal.Header
            closeButton
            style={{ border: "none" }}
            id="SettingsModalHeader"
          >
            Settings
          </Modal.Header>
          <Modal.Body>
            {contentToShow ? displayEdit() : <SettingTypeChoice />}
          </Modal.Body>
          <Modal.Footer style={{ flexDirection: "row-reverse" }}>
            <Button
              onClick={() => {
                setContentToShow(null);
                setShow(false);
              }}
              className="settingsItemBtnDull"
              // disabled={currentCategories.length < MinCategories}
              // variant="primary"
              // onClick={doUpdateUsersCategories}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
