import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const Error404 = ({ redirect = true }) => {
  const history = useHistory();

  const doRedirect = () => {
    setTimeout(() => {
      history.push("/");
    }, 2000);
  };

  useEffect(() => {
    if (redirect) doRedirect();
  }, []);

  return <div>Page not found{redirect && ", redirecting..."}</div>;
};
