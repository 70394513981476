import { useState, useEffect } from "react";
import PortableText from "../../components/PortableText";

const Container = ({ children }) => {
  return (
    <div className="using-tailwind bg-gray-800 min-h-screen px-4 pt-24 sm:pt-36 pb-48">
      {children}
    </div>
  );
};

export const MagazinePost = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`/api/magazine/${window.location.pathname.split("/").pop()}`)
      .then((res) => res.json())
      .then(setData);
  }, []);

  if (!data) return <Container />;

  if (data.error === "NOT_FOUND") {
    return (
      <Container>
        <div>
          <p className="text-xl font-extrabold text-white text-center border-2 border-gray-800 bg-gray-950 px-4 py-3 max-w-max mx-auto">
            Post Not Found
          </p>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="max-w-screen-sm mx-auto">
        <div
          className={[
            "prose prose-invert prose-2xl max-w-none",
            "prose-img:max-h-[60vh] prose-img:mx-auto",
            "max-sm:prose-h1:text-5xl",
            "prose-a:text-[#24D7DE] hover:prose-a:text-[#24D7DE] visited:prose-a:text-[rgb(239,68,68)] visited:hover:prose-a:text-[rgb(239,68,68)]",
          ].join(" ")}
        >
          <div className="-mx-0.5">
            <h1>{data.post.title}</h1>
          </div>

          {data.post.cover_image && (
            <img
              src={data.post.cover_image + "?w=1800&auto=format"}
              className="mt-6 md:mt-10 w-[calc(100%+1.5rem)] -ml-[0.75rem] lg:w-[calc(100%+10rem)] lg:-ml-[5rem] max-w-none"
            />
          )}

          <PortableText value={data.post.body} />
        </div>

        <div className="mt-16 flex flex-col sm:flex-row gap-y-4 justify-between">
          {data.prev ? (
            <a
              href={`/magazine/${data.prev.slug}`}
              className="block border-2 border-gray-800 px-4 py-3 hover:no-underline hover:bg-cyan-100 transition-colors"
            >
              <p className="my-0 uppercase text-gray-800/40 font-medium text-sm tracking-wide">
                &larr; Previous post
              </p>
              <p className="mb-0 mt-1 font-medium text-xl text-gray-800">
                {data.prev.title}
              </p>
            </a>
          ) : (
            <div />
          )}

          {data.next && (
            <a
              href={`/magazine/${data.next.slug}`}
              className="block border-2 border-gray-800 px-4 py-3 text-right hover:no-underline hover:bg-cyan-100 transition-colors"
            >
              <p className="my-0 uppercase text-gray-800/40 font-medium text-sm tracking-wide">
                Next post &rarr;
              </p>
              <p className="mb-0 mt-1 font-medium text-xl text-gray-800">
                {data.next.title}
              </p>
            </a>
          )}
        </div>
      </div>
    </Container>
  );
};
