import Axios from "axios";

export const globalFetch = async (url, method = "GET", data) => {
  try {
    const options = {
      method,
      url,
    };
    if (data) {
      options.data = data;
    }

    return await Axios(options);
  } catch (e) {
    throw e;
  }
};
