import MoonLoader from "react-spinners/ClipLoader";
import styled from "styled-components";
import PropTypes from "prop-types";

const Loader1Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: 50vh;
  align-items: center;
`;

const Loader1Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Loader1 = ({ text }) => {
  return (
    <Loader1Container>
      <Loader1Content>
        <MoonLoader />
        <p>{text}</p>
      </Loader1Content>
    </Loader1Container>
  );
};

Loader1.propTypes = {
  text: PropTypes.string,
};
