import { useContext, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  Loader1,
  BlogTopicCard,
  PageTitle,
  SetCategoriesModal,
  DisplayLocalText,
} from "../../components";
import { globalFetch } from "../../utils";
import "./style.css";
import { LangContext, UserContext } from "../../context";

export const Blog = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isNewBlogsLoading, setIsNewBlogsLoading] = useState(true);
  const history = useHistory();
  const [blogTopics, setBlogTopics] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const selectedLang = useContext(LangContext);
  const userContext = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [preferredBlogs, setPreferredBlogs] = useState([]);
  const [newestBlogs, setNewestBlogs] = useState([]);
  const MinCategories = 3;

  const doNav = (selectedBlogTopicId) => {
    history.push(`/programs/${selectedBlogTopicId}`);
  };

  const doSetCategories = (data) => {
    let allCats = data.reduce((acc, cur) => {
      if (cur.categories) {
        acc = [...acc, ...cur.categories];
      }
      return acc;
    }, []);
    allCats = allCats.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        allCats.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    let catBlogs = allCats.reduce((acc, cur) => {
      const blogs = data.filter((b) => {
        if (b.categories) {
          let bCats = b.categories.map((bc) => {
            return bc.title;
          });
          return bCats.includes(cur.title);
        } else {
          return false;
        }
      });
      let blog = {
        category: cur.title,
        blogs,
      };
      acc.push(blog);
      return acc;
    }, []);

    catBlogs = catBlogs.filter((b) => {
      return b.blogs.length > 4;
    });
    setBlogCategories(catBlogs);
  };

  const fetchBlogs = async () => {
    try {
      const { data } = await globalFetch("/api/blogTopic");
      setBlogTopics(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 900);

      doSetCategories(data);
    } catch (e) {
      alert("Error fetching blog topics");
      console.log(`Error fetching blog topics ${e}`);
      history.push(`/`);
    }
  };

  const fetchNewestBlogs = async () => {
    try {
      const { data } = await globalFetch("/api/blogTopic/newest");
      return data;
    } catch (e) {
      alert("Error fetching blog topics");
      console.log(`Error fetching blog topics ${e}`);
      history.push(`/`);
    }
  };

  const programsRow = (titleData, type = "all") => {
    const titleKey = `title${selectedLang === "EN" ? "" : selectedLang}`;

    const getProgramsColumns = (data) => {
      return data.reduce((acc, topic, idx) => {
        if (topic[titleKey]) {
          acc.push(
            <Col
              lg="3"
              sm="6"
              xs="6"
              className="blogTopicCol"
              key={`topic-${idx}`}
            >
              <a
                style={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  doNav(topic._id);
                }}
              >
                <BlogTopicCard data={topic} id={`topic-${idx}`} />
              </a>
            </Col>
          );
        }
        return acc;
      }, []);
    };

    let programs;
    if (type === "prefered") {
      programs = getProgramsColumns(preferredBlogs);
    } else if (type === "new") {
      programs = getProgramsColumns(newestBlogs);
    } else {
      programs = getProgramsColumns(blogTopics);
    }
    return (
      <>
        <Container fluid id="blog-page">
          <Row>
            <Col>
              <h3 id="pageTitle">
                <DisplayLocalText data={titleData} targetDataKey={"title"} />
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid className="blog-page-programs">
          <Row id="programs-row">{programs}</Row>
        </Container>
      </>
    );
  };

  const programsRowCat = (data) => {
    console.log(data);
    const getProgramsColumns = (data) => {
      return data.blogs.reduce((acc, topic, idx) => {
        acc.push(
          <Col
            lg="3"
            sm="6"
            xs="6"
            className="blogTopicCol"
            key={`topic-${idx}`}
          >
            <a
              style={{
                textDecoration: "none",
              }}
              onClick={() => {
                doNav(topic._id);
              }}
            >
              <BlogTopicCard data={topic} id={`topic-${idx}`} />
            </a>
          </Col>
        );

        return acc;
      }, []);
    };

    let programs = data.blogs.length > 0 ? getProgramsColumns(data) : null;

    return (
      <>
        <Container fluid id="blog-page">
          <Row>
            <Col>
              <h3 id="pageTitle">{data.category}</h3>
            </Col>
          </Row>
        </Container>
        <Container fluid className="blog-page-programs">
          <Row id="programs-row">{programs}</Row>
        </Container>
      </>
    );
  };

  const doDisplayCategoriesModal = async () => {
    if (userContext.userData.preferredCategories) {
      if (userContext.userData.preferredCategories.length < MinCategories) {
        //has enough selected
        const { preferredCategories } = userContext.userData;
        setSelectedCategories(preferredCategories);
      } else {
        const { preferredCategories } = userContext.userData;
        setSelectedCategories(preferredCategories);
        return;
      }
    }
    setShowModal(true);
  };

  const doSetPreferredBlogs = (preferredCategories) => {
    const preferred = blogTopics.filter((blog) => {
      if (blog.categories) {
        const prefBlogs = blog.categories.filter((cat) => {
          return preferredCategories.includes(cat._id);
        });
        if (prefBlogs.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    setPreferredBlogs(preferred);
  };

  const doSetNewestBlog = async () => {
    const data = await fetchNewestBlogs();
    setTimeout(() => {
      setIsNewBlogsLoading(false);
    }, 1000);
    setNewestBlogs(data);
  };

  useEffect(() => {
    if (userContext.userData) {
      doDisplayCategoriesModal();
    }
  }, [userContext]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    if (blogTopics.length > 0 && selectedCategories.length > 2) {
      doSetPreferredBlogs(userContext.userData.preferredCategories);
      doSetNewestBlog();
    }
  }, [blogTopics, selectedCategories]);

  return (
    <>
      <PageTitle prevTitle={""} prevLink={`/`} />
      <SetCategoriesModal
        doShow={showModal}
        userContext={userContext}
        selectedCategories={selectedCategories}
        MinCategories={MinCategories}
      />

      {!isLoading &&
        programsRow(
          {
            title: "Suggested Programs",
            titlePT: "Todos os Programas",
          },
          "prefered"
        )}

      {!isLoading &&
        programsRow(
          {
            title: "What's New",
            titlePT: "Todos os Programas",
          },
          "new"
        )}

      {!isLoading &&
        !isNewBlogsLoading &&
        programsRow({
          title: "All Programs",
          titlePT: "Todos os Programas",
        })}

      {!isLoading &&
        !isNewBlogsLoading &&
        blogCategories.length > 0 &&
        blogCategories.map((bc) => programsRowCat(bc))}

      {isLoading && <Loader1 />}
    </>
  );
};
