import { Container, Row, Col } from "react-bootstrap";
import "./style.css";

export const PrivacyPolicy = () => {
  return (
    <Container id="policy-page">
      <Row>
        <Col>
          <h1>Privacy Policy</h1>
          <p>
            This Privacy Policy explains our practices regarding the collection,
            use, and disclosure of certain information, including your personal
            information, in connection with the{" "}
            <a href="https://mrwelltravelled.com" target={"_blank"}>
              mrwelltravelled.com
            </a>{" "}
            site and service.
          </p>
          <h3>Contacting Us</h3>
          <p>
            If you have general questions about your account or specific
            questions about this Privacy Policy, please visit our{" "}
            <a href="/contact-us" target={"_blank"}>
              contact page
            </a>
            .
          </p>
          <h3>Collection of Information</h3>
          <p>We receive and store information about you such as:</p>
          <ul>
            <li>
              <b>Information you provide to us:</b> We collect information about
              you and your use of this site, as well as interactions with
              Well-Travelled Explorations team members and contractors. This
              information includes:
              <ul>
                <li>
                  your activity on mrwelltravelled.com, including but not
                  limited to preferred language selection, search queries,
                  categories viewed, programs viewed, and episodes viewed;
                </li>
                <li>
                  your interactions with our emails, texts, and online messaging
                  channels;
                </li>
                <li>
                  details of your interactions with our team members and
                  contractors, such as the date, time and reason for contacting
                  us, transcripts of any chat conversations, and if you call us,
                  your phone number and call recordings;
                </li>
                <li>
                  statistics on page views, referring source (for example,
                  referral URLs), IP address (which may tell us your general
                  location), browser and standard web server log information;
                </li>
              </ul>
            </li>
            <li>
              <b>Information we collect automatically:</b> We collect
              information about you and your use of this site, as well as
              interactions with Well-Travelled Explorations team members and
              contractors. This information includes:
              <ul>
                <li>
                  your activity on{" "}
                  <a href="/" target={"_blank"}>
                    mrwelltravelled.com
                  </a>
                  , including but not limited to preferred language selection,
                  search queries, categories viewed, programs viewed, and
                  episodes viewed;
                </li>
                <li>
                  your interactions with our emails, texts, and online messaging
                  channels;
                </li>
                <li>
                  details of your interactions with our team members and
                  contractors, such as the date, time and reason for contacting
                  us, transcripts of any chat conversations, and if you call us,
                  your phone number and call recordings;
                </li>
                <li>
                  statistics on page views, referring source (for example,
                  referral URLs), IP address (which may tell us your general
                  location), browser and standard web server log information;
                </li>
              </ul>
            </li>
            <li>
              <b>Information from other sources:</b> We also obtain information
              from other sources. We protect this information according to the
              practices described in this Privacy Policy, plus any additional
              restrictions imposed by the source of the data. These sources vary
              over time, but could include:
              <ul>
                <li>
                  service providers that help us determine a location based on
                  your IP address in order to customize our service and for
                  other uses consistent with this Privacy Policy;
                </li>
                <li>
                  security service providers who provide us with information to
                  secure our systems, prevent fraud and help us protect the
                  security of{" "}
                  <a href="/contact-us" target={"_blank"}>
                    mrwelltravelled.com
                  </a>{" "}
                  accounts;
                </li>
                <li>
                  payment service providers who provide us with payment or
                  balance information, or updates to that information, based on
                  their relationship with you;
                </li>
                <li>
                  online and offline data providers, from which we obtain
                  aggregated demographic, interest based and online advertising
                  related data;
                </li>
                <li>
                  publicly-available sources such as publicly available posts on
                  social media platforms and information available through
                  public databases associating IP addresses with internet
                  service providers (ISPs);
                </li>
              </ul>
            </li>
          </ul>
          <h3>Use of Information</h3>
          <p>
            We use information to provide, analyze, administer, enhance and
            personalize our services and marketing efforts, to process your sign
            up, your payments, and to communicate with you on these and other
            topics. For example, we use such information to:
          </p>
          <ul>
            <li>
              determine your general geographic location, provide localized
              content, provide you with customized and personalized viewing
              recommendations for content that we think will be of interest to
              you, and help us quickly and efficiently respond to inquiries and
              requests;
            </li>
            <li>
              secure our systems, prevent fraud and help us protect the security
              of{" "}
              <a href="/" target={"_blank"}>
                mrwelltravelled.com
              </a>{" "}
              accounts;
            </li>
            <li>
              prevent, detect and investigate potentially prohibited or illegal
              activities, including fraud, and to enforce our terms;
            </li>
            <li>
              analyze and understand our audience, improve our service
              (including our user interface experiences and service performance)
              and optimize content selection, recommendation algorithms and
              delivery;
            </li>
            <li>
              communicate with you concerning our site so that we can send you
              news about site updates, special offers, promotional
              announcements, consumer surveys, and to assist you with
              operational requests such as password reset requests. These
              communications may be by various methods, such as email, push
              notifications, text message, and other online messaging channels.
            </li>
          </ul>
          <p>
            Our recommendations system strives to provide personalized content
            recommendations based on past viewing. However, our recommendations
            system does not infer or attach socio-demographic or economic
            information (like gender, race, or income) to you.
          </p>
          <h3>Disclosure of Information</h3>
          <p>
            We disclose your information for certain purposes and to third
            parties, as described below:
          </p>
          <ul>
            <li>
              <b> Service Providers:</b> We use other companies, agents or
              contractors ("Service Providers") to perform services on our
              behalf or to assist us with the provision of services to you. For
              example, we engage Service Providers to provide marketing,
              advertising, communications, security, infrastructure and IT
              services, to customize, personalize and optimize our service, to
              provide bank account or balance information, to process credit
              card transactions or other payment methods, to provide customer
              service, to analyze and enhance data (including data about users'
              interactions with our service), and to process and administer
              consumer surveys. In the course of providing such services, these
              Service Providers may have access to your personal or other
              information. We do not authorize them to use or disclose your
              personal information except in connection with providing their
              services (which includes maintaining and improving their
              services).
            </li>
            <li>
              <b>Promotional offers:</b> We may offer joint promotions or
              programs that, in order for your participation, will require us to
              share your information with third parties. In fulfilling these
              types of promotions, we may share your name and other information
              in connection with fulfilling the incentive. Please note that
              these third parties are responsible for their own privacy
              practices.
            </li>
            <li>
              <b>
                Protection of Well-Travelled Explorations and portfolio of
                brands:
              </b>
              Well-Travelled Explorations and its Service Providers may disclose
              and otherwise use your personal and other information where we or
              they reasonably believe such disclosure is needed to (a) satisfy
              any applicable law, regulation, legal process, or governmental
              request, (b) enforce applicable terms of use, including
              investigation of potential violations thereof, (c) detect,
              prevent, or otherwise address illegal or suspected illegal
              activities (including payment fraud), security or technical
              issues, or (d) protect against harm to the rights, property or
              safety of Well-Travelled Explorations, its users or the public, as
              required or permitted by law.
            </li>
            <li>
              <b>Business transfers:</b>
              In connection with any reorganization, restructuring, merger or
              sale, or other transfer of assets, we will transfer information,
              including personal information, provided that the receiving party
              agrees to respect your personal information in a manner that is
              consistent with our Privacy Policy.
            </li>
          </ul>
          <p>
            Whenever in the course of sharing information we transfer personal
            information to other countries, we will ensure that the information
            is transferred in accordance with this Privacy Policy and as
            permitted by the applicable laws on data protection.
          </p>
          <p>
            You may also choose to disclose your information in the following
            ways:
          </p>
          <ul>
            <li>
              certain portions of our service may contain a tool which gives you
              the option to share information by email, text message and social
              or other sharing applications, using the clients and applications
              on your smart device;
            </li>
            <li>
              social plugins and similar technologies allow you to share
              information; and
            </li>
          </ul>
          <p>
            Social plugins and social applications are operated by the social
            networks themselves, and are subject to their terms of use and
            privacy policies.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
