import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Row, Col, Container, DropdownButton, Dropdown } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { DisplayLocalText, Loader1, PageTitle } from "../../components";
import { globalFetch } from "../../components/utils/globalFetch";
import "./style.css";

export const BlogTopic = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { blogTopicId } = useParams();

  const [blogTopicData, setBlogTopicData] = useState(null);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);

  const doNav = (selectedBlogTopicId) => {
    history.push(`/programs/${blogTopicId}/${selectedBlogTopicId}`);
  };

  const doSetTopicSeasons = (posts) => {
    //TODO do insertion sort by episode when adding posts
    const foundSeasons = [];

    const addToSeason = (acc, cur) => {
      const update = { ...acc };
      update[cur.seasonNumber].push(cur);
      return update;
    };
    const addNewSeason = (acc, cur) => {
      const newSeasonNumber = cur.seasonNumber;
      const update = { ...acc, [newSeasonNumber]: [cur] };
      foundSeasons.push(newSeasonNumber);
      return update;
    };
    const doUpdateSeasons = (acc, cur) => {
      if (foundSeasons.includes(cur.seasonNumber)) {
        return addToSeason(acc, cur);
      } else {
        return addNewSeason(acc, cur);
      }
    };
    const res = posts.reduce((acc, cur) => {
      if (!cur.seasonNumber) {
        cur = { ...cur, seasonNumber: 0 };
      }

      const updatedSeasons = doUpdateSeasons(acc, cur);
      acc = updatedSeasons;

      return acc;
    }, []);
    setSeasons(res);
  };

  const doSetMinSeason = () => {
    if (Object.keys(seasons).length > 0) {
      const minSeasonNumber = Math.min(...Object.keys(seasons));
      setSelectedSeason(minSeasonNumber);
    }
  };

  const fetchBlogTopic = async () => {
    if (blogTopicId) {
      try {
        const { data } = await globalFetch(
          `/api/blogTopic/${blogTopicId}/posts`
        );
        //TODO get index out
        if (data.length > 0) {
          setBlogTopicData({
            title: data[0].title,
            titlePT: data[0].titlePT,
            imageUrl: data[0].mainImage,
            blogPosts: data[0].blogPosts,
          });
          if (data[0].blogPosts) doSetTopicSeasons(data[0].blogPosts);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      } catch (e) {
        alert("Error fetching blog topic");
        console.log(`Error fetching blog topic ${e}`);
        history.push(`/`);
      }
    }
  };

  const displaySeasonLabel = (val) => {
    return val == 0 ? "Featured" : `Season ${val}`;
  };
  const doDisplaySeasonSelector = () => {
    return (
      !(Object.keys(seasons).length === 1 && seasons[0]) ||
      blogTopicData.blogPosts.length
    );
  };

  const doDisplaySeasonPosts =
    seasons[selectedSeason] && seasons[selectedSeason].length;

  useEffect(() => {
    fetchBlogTopic();
  }, []);

  useEffect(() => {
    if (Object.keys(seasons).length > 0) {
      doSetMinSeason();
    }
  }, [seasons]);

  const displayContent = () => {
    if (blogTopicData.blogPosts.length > 0) {
      return (
        <>
          {doDisplaySeasonSelector() && (
            <Row>
              <Col>
                <DropdownButton
                  title={displaySeasonLabel(selectedSeason)}
                  id={"dropdown-menu-align-end-light-season"}
                  className={"text-center mb-3"}
                >
                  {Reflect.ownKeys(seasons).map((season, idx) => (
                    <Dropdown.Item
                      eventKey="1"
                      onClick={(e) => {
                        setSelectedSeason(e.target.id);
                      }}
                      id={season}
                      key={`idx-${idx}`}
                    >
                      {displaySeasonLabel(season)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
          )}
          <Row style={{ marginBottom: "50px" }}>
            {doDisplaySeasonPosts &&
              seasons[selectedSeason].map((blogPost, idx) => (
                <Col
                  xs={12}
                  key={`blogPost-${idx}`}
                  className="BlogSubTopicTitleContainer"
                >
                  <div
                    style={{
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      doNav(blogPost._id);
                    }}
                  >
                    <h1 className="BlogSubTopicTitle">
                      <DisplayLocalText
                        data={blogPost}
                        targetDataKey={"title"}
                      />
                    </h1>
                  </div>
                </Col>
              ))}
          </Row>
        </>
      );
    } else {
      return <p className="text-center mb-5">No episodes available</p>;
    }
  };

  return (
    <>
      {blogTopicData && (
        <PageTitle
          prevTitle={
            <FontAwesomeIcon
              size={"lg"}
              style={{ color: "salmon" }}
              icon={faChevronLeft}
              id="backBtn"
            />
          }
          title={""}
          prevLink={`/programs`}
        />
      )}
      <Container fluid id="blogTopic-page">
        {!isLoading && (
          <>
            {blogTopicData ? (
              <>
                <Row id={"blogTopic-page-hero"}>
                  <Col>
                    <div id="blogTopicImgContainer">
                      <img
                        alt={`BlogTopicImage`}
                        id="blogTopicImage"
                        src={blogTopicData.imageUrl}
                      />
                    </div>
                    <div id="blogTopicTitleContainer">
                      <h2 id="blogTopicTitle">
                        <DisplayLocalText
                          data={blogTopicData}
                          targetDataKey={"title"}
                        />
                      </h2>
                      <div id="blogTopicTitleDividerContainer">
                        <div id="blogTopicTitleDivider"></div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* season selector */}
                {displayContent()}
              </>
            ) : (
              <p className="text-center mb-5">No episodes available</p>
            )}
          </>
        )}
        {isLoading && <Loader1 />}
      </Container>
    </>
  );
};
