import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
//TODO update the redirect url using env var

const redirectUri =
  process.env.isDev === true || "true"
    ? "http://localhost:3000/"
    : "https://www.mrwelltravelled.com/";

ReactDOM.render(
  <Auth0Provider
    domain="dev-5hjyp9un.us.auth0.com"
    clientId="okUIbYF5US4JUIp6XHz97jsBVztyhkBO"
    useRefreshTokens={true}
    cacheLocation={"localstorage"}
    redirectUri={"https://www.mrwelltravelled.com/"}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
