import { useEffect, useState } from "react";
import {
  Home,
  Blog,
  BlogTopic,
  BlogPost,
  Error404,
  SignUp,
  PrivacyPolicy,
  Terms,
  LegalNotices,
  PaymentAuth,
  AfroverseCalendar,
  ContactUs,
  MagazinePost,
} from "./pages";
import { Navigation, ProtectedComp } from "./components";
import { LangContext, UserContext } from "./context";
import {
  createLog,
  getIpLocation,
  translateMap,
  setCookie,
  getCookie,
} from "./utils";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { VimeoPage } from "./pages/VimeoPage";

function App() {
  const [selectedLang, setSelectedLang] = useState("EN");

  const [userData, setUserData] = useState(null);

  const handleClientLang = async () => {
    const cookieLang = await getCookie("explorations-lang");
    if (cookieLang) {
      setSelectedLang(cookieLang);
      console.log(`Lang found in cookies ${cookieLang}`);
    } else {
      try {
        const { country_code } = await getIpLocation();
        if (translateMap["PT"].countryCodes.includes(country_code)) {
          setSelectedLang("PT");
          await setCookie("explorations-lang", "PT");
        } else {
          await setCookie("explorations-lang", "EN");
        }
      } catch (e) {
        console.log(`Error setting lang - ${e}`);
      }
    }
  };

  const handleLangChange = (e) => {
    const oldLang = selectedLang;
    const newLang = e;
    setSelectedLang(newLang);
    setCookie("explorations-lang", newLang);
    try {
      createLog(
        null,
        "COOKIES SET",
        `Language updated from ${oldLang} to ${newLang}`
      );
    } catch (e) {
      console.log(`Could not set cookies - ${e}`);
    }
  };

  const doHttpsRedirect = () => {
    const { protocol, href } = window.location;
    if (!href.includes("localhost") && protocol === "http:")
      window.location.href = "https://www.mrwelltravelled.com/";
  };

  useEffect(() => {
    doHttpsRedirect();
    handleClientLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <UserContext.Provider value={{ userData, setUserData }}>
        <Navigation
          handleLangChange={handleLangChange}
          selectedLang={selectedLang}
        />
        <LangContext.Provider value={selectedLang}>
          <div className="App">
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route exact path="/signup/registration">
                <SignUp />
              </Route>
              <ProtectedComp exact path="/programs">
                <Blog />
              </ProtectedComp>
              <ProtectedComp exact path="/programs/:blogTopicId">
                <BlogTopic />
              </ProtectedComp>
              <Route exact path="/programs/:blogTopicId/:blogPostId">
                <BlogPost />
              </Route>
              <Route exact path="/magazine/:slug">
                <MagazinePost />
              </Route>
              <Route exact path="/video/:id">
                <VimeoPage />
              </Route>
              <Route exact path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/legalNotice">
                <LegalNotices />
              </Route>
              <Route exact path="/payment-auth">
                <PaymentAuth />
              </Route>
              <Route path="/afroverseCalendar" exact>
                <AfroverseCalendar />
              </Route>
              <Route path="/contact-us" exact>
                <ContactUs />
              </Route>
              <Error404 />
            </Switch>
          </div>
        </LangContext.Provider>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
