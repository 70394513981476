import { DisplayLocalText } from "..";
import "./style.css";

export const BlogTopicCard = ({ data, color = "black" }) => {
  const { _id: id, mainImage: imageUrl } = data;
  return (
    <div className="BlogTopicCard">
      <div className="BlogTopicCardImageContainer">
        <img
          alt={`BlogTopicImage-${id}`}
          className="BlogTopicCardImage"
          height="100%"
          width="100%"
          src={imageUrl}
        />
      </div>
      <div className="BlogTopicCardTitleContainer">
        <p className="BlogTopicCardTitle" style={{ color }}>
          <DisplayLocalText data={data} targetDataKey={"title"} />
        </p>
      </div>
    </div>
  );
};
