import { useContext, useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  BlogTopicCard,
  LoginButton,
  SignUpModal,
  YoutubeModal,
} from "../../components";
import { deleteCookie, getCookie, globalFetch, setCookie } from "../../utils";
import { UserContext } from "../../context";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import defaultHero from "./hero.png";
import PortableText from "../../components/PortableText";

const FAQ = ({ title, body }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const FaqTextContent = () => {
    return (
      <Row className="FAQ-text-container">
        <Col className="mt-3 mb-6 space-y-8 [&_p]:!leading-normal [&_li]:list-disc [&_li]:text-[28px] [&_ul]:ml-6">
          <div className="prose prose-invert text-2xl text-white prose-a:text-brand-cyan hover:prose-a:text-brand-cyan">
            <PortableText value={body} />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Container className="FAQ-Container">
      <Row className="FAQ-header-container" onClick={toggleOpen}>
        <Col xs="10">
          <h1 className="FAQ-header-text">{title}</h1>
        </Col>
        <Col xs="2" className="text-center">
          <FontAwesomeIcon
            size={"lg"}
            icon={open ? faTimes : faPlus}
            className="FAQ-header-icon"
          />
        </Col>
      </Row>
      {open ? <FaqTextContent /> : null}
    </Container>
  );
};

const FAQS = () => {
  const [faqs, setFaqs] = useState([]);

  const fetchFAQs = async () => {
    try {
      const { data } = await globalFetch(`/api/faqs`);
      setFaqs(data);
    } catch (e) {
      console.log(`Error fetching FAQs - ${e}`);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  return (
    <Col lg="12" id="FAQ-section">
      {faqs.map((faq) => (
        <FAQ title={faq.title} body={faq.body} />
      ))}
    </Col>
  );
};
//TODO use protectedComp
export const Home = () => {
  const userContext = useContext(UserContext);

  const [isLoadingDynamicContent, setIsLoadingDynamicContent] = useState(true);

  const [section1Hero, setSection1Hero] = useState({
    backgroundImage: `url(${defaultHero})`,
  });
  const [section1HeroText, setSection1HeroText] = useState();
  const [section1SubText, setSection1SubText] = useState([
    "$5.42 per month. Billed annually.",
    "ETH, USDC and BTC accepted.",
  ]);

  const [section1HeadingText, setSection1HeadingText] = useState("");
  const [section1HeadingSubText, setSection1HeadingSubText] = useState([
    "Stories, video, and audio from across the Afroverse",
  ]);

  const [section2SubText, setSection2SubText] = useState([
    "Read, watch, and listen on your smartphone, tablet, laptop, or desktop computer",
  ]);

  const [section2HeadingImage, setSection2HeadingImage] = useState(
    process.env.PUBLIC_URL + "/images/enjoyEverywhere.png"
  );

  const [section2SecondaryImage, setSection2SecondaryImage] = useState(
    process.env.PUBLIC_URL + "/images/sampleContent.png"
  );

  const [section1HeadingButton, setSection1HeadingButton] = useState(null);

  const [isAnimationDone, setIsAnimationDone] = useState(false);

  const [footerSection, setFooterSection] = useState([]);

  const history = useHistory();

  const [doShowModal, setDoShowModal] = useState(false);
  const [doShowYoutubeModal, setDoShowYoutubeModal] = useState(false);

  const { isLoading, isAuthenticated, error, user, loginWithRedirect } =
    useAuth0();

  const [programRows, setProgramRows] = useState([]);

  const doLogin = async () => {
    let options = {
      screen_hint: "signup",
      redirect_uri: "",
      appState: {
        returnTo: "",
      },
    };

    setCookie("exlorations-redirect", "/signup/registration");
    await loginWithRedirect(options);
  };

  const getUserData = async (user) => {
    const { data } = await globalFetch(`/api/user/${user.email}`);
    return data;
  };

  const getSection1Rows = async () => {
    const { data } = await globalFetch(`/api/section1rows`);
    setProgramRows(data);
    return data;
  };

  const getFooterSection = async () => {
    const mapHeaders = (headers) => {
      return headers.map((header) => {
        return header.children[0].text;
      });
    };

    const { data } = await globalFetch(`/api/footerSection`);

    if (data && Array.isArray(data)) {
      const updatedData = data.map((footerSection) => {
        const { footerLinks, heading } = footerSection;
        const headers =
          heading && Array.isArray(heading) ? mapHeaders(heading) : [];

        return {
          headers,
          footerLinks,
        };
      });

      setFooterSection(updatedData);
    }
  };

  const getHeroSection = async () => {
    try {
      let { data } = await globalFetch(`/api/heroSection`);
      const { backgroundImg, subText, textImage, buttonImage } = data[0];

      try {
        setSection1Hero({
          backgroundImage: `url(https://cdn.sanity.io/images/jcvgjqzy/blog_data/${backgroundImg.asset._ref
            .replace("image-", "")
            .replace(/-(png|jpg|jpeg)/, ".$1")})`,
        });
      } catch (e) {
        console.log(`Could not set background hero image - ${e}`);
      }

      try {
        if (textImage?.asset?._ref) {
          setSection1HeroText(
            `https://cdn.sanity.io/images/jcvgjqzy/blog_data/${textImage.asset._ref
              .replace("image-", "")
              .replace(/-(png|jpg|jpeg)/, ".$1")}`
          );
        }
      } catch (e) {
        console.log(`Could not set hero text image - ${e}`);
      }

      try {
        setSection1SubText(subText);
      } catch (e) {
        console.log(`Could not set hero subtext - ${e}`);
      }

      try {
        setSection1HeadingButton(
          `https://cdn.sanity.io/images/jcvgjqzy/blog_data/${buttonImage.asset._ref
            .replace("image-", "")
            .replace(/-(png|jpg|jpeg)/, ".$1")}`
        );
      } catch (e) {
        console.log(`Could not set hero button image - ${e}`);
      }
    } catch (e) {
      console.log(`Could not set background hero stuff - ${e}`);
    }
  };

  const getSection2 = async () => {
    try {
      let { data } = await globalFetch(`/api/section2`);

      const { textImage, subText, secondaryImage } = data[0];

      try {
        setSection2HeadingImage(
          `https://cdn.sanity.io/images/jcvgjqzy/blog_data/${textImage.asset._ref
            .replace("image-", "")
            .replace(/-(png|jpg|jpeg)/, ".$1")}`
        );
      } catch (e) {
        setSection2HeadingImage(
          process.env.PUBLIC_URL + "/images/enjoyEverywhere.png"
        );
        console.log(`Could not set section 2 header image image - ${e}`);
      }

      try {
        setSection2SecondaryImage(
          `https://cdn.sanity.io/images/jcvgjqzy/blog_data/${secondaryImage.asset._ref
            .replace("image-", "")
            .replace(/-(png|jpg|jpeg)/, ".$1")}`
        );
      } catch (e) {
        setSection2SecondaryImage(
          process.env.PUBLIC_URL + "/images/sampleContent.png"
        );
        console.log(`Could not set section 2 sub image text image - ${e}`);
      }

      try {
        if (subText && subText.length > 0) setSection2SubText(subText);
      } catch (e) {
        console.log(`Could not set section2 subtext - ${e}`);
      }
    } catch (e) {
      console.log(`Could not set section 2 stuff - ${e}`);
    }
  };

  const getSection1HeadingData = async () => {
    try {
      let { data } = await globalFetch(`/api/section1Heading`);
      const { subText, textImage } = data[0];

      try {
        setSection1HeadingText(
          `https://cdn.sanity.io/images/jcvgjqzy/blog_data/${textImage.asset._ref
            .replace("image-", "")
            .replace(/-(png|jpg|jpeg)/, ".$1")}`
        );
      } catch (e) {
        console.log(`Could not set section 1 heading image - ${e}`);
      }
      try {
        setSection1HeadingSubText(subText);
      } catch (e) {
        console.log(`Could not set section 1 sub text - ${e}`);
      }
    } catch (e) {
      console.log(`Could not set fetch heading 1 stuff - ${e}`);
    }
  };

  const createNewUser = async (user) => {
    const { data } = await globalFetch(`/api/user`, "POST", user);
    return data;
  };

  const doDisplayPopUp = () => {
    let scrolled = document.scrollingElement.scrollTop;
    if (scrolled > 800 && !doShowModal) {
      setTimeout(() => {
        setDoShowModal(true);
      }, 5000);
    }
  };

  const handleSignIn = async (animationDur = 900) => {
    if (isAuthenticated) {
      let redirectLocation = await getCookie("exlorations-redirect");

      if (redirectLocation && redirectLocation !== "/programs") {
        deleteCookie("exlorations-redirect");
        try {
          const data = await createNewUser(user);
          await userContext.setUserData(data);
        } catch (e) {
          //user account already exists
          if (e.response.status === 409) {
            const data = await getUserData(user);
            await userContext.setUserData(data);
            if (data.isAccountSetupCompleted) {
              history.push("/programs");
              return;
            }
            if (data.paymentType === "coinbase") {
              history.push("/payment-auth");
            } else {
              history.push("/signup/registration");
            }
          } else if (e.response.status === 404) {
            const data = await createNewUser(user);
            await userContext.setUserData(data);
            history.push("/signup/registration");
            return;
          } else {
            console.log(e);
            alert("something happened when getting the user");
            return;
          }
        }
      } else {
        // log in
        try {
          const data = await getUserData(user);
          await userContext.setUserData(data);
          const { isAccountSetupCompleted, paymentType } = data;
          // user needs to complete their account set up or crypto payment is loading
          if (!isAccountSetupCompleted) {
            if (paymentType === "coinbase") {
              history.push("/payment-auth");
            } else {
              history.push("/signup/registration");
            }
            return;
          }
        } catch (e) {
          if (e.response.status === 404) {
            const data = await createNewUser(user);
            await userContext.setUserData(data);
            history.push("/signup/registration");
            return;
          }
          //user some how does not exist
          console.log(e);
          alert("something happened when fetching the user");
          return;
        }
        redirectLocation = "/programs";
      }
      history.push(redirectLocation);
    } else {
      try {
        await getSection1Rows();
        await getFooterSection();
        await getHeroSection();
        await getSection1HeadingData();
        await getSection2();
        setIsLoadingDynamicContent(false);
      } catch (e) {
        setIsLoadingDynamicContent(false);
      }
      setTimeout(() => {
        setIsAnimationDone(true);
      }, animationDur);
    }
  };

  const AppCover = () => {
    return (
      <div
        id="appLoadCover"
        className={`${isLoadingDynamicContent ? "" : "loaded"}`}
      />
    );
  };

  const DisplayFooter = ({ footerData }) => {
    const sections = footerData.reduce((acc, data) => {
      const { headers, footerLinks } = data;
      const headerTags = headers.map((header) => {
        return (
          <p className="footer-text text-center" style={{ lineHeight: 1.2 }}>
            {header}
          </p>
        );
      });

      const footerTags = footerLinks.map((footerLink, idx) => {
        return (
          <>
            <a
              className="footer-link mr-2"
              target={"_blank"}
              href={footerLink.url}
            >
              {footerLink.title}
            </a>
            {idx + 1 !== footerLinks.length && <Divider />}
          </>
        );
      });

      acc.push(<Col xs={12}>{headerTags}</Col>);

      acc.push(
        <Col xs={12}>
          <div className="text-center mb-3" id="footer-links-container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              {footerTags}
            </div>
          </div>
        </Col>
      );

      return acc;
    }, []);
    return sections;
  };

  const PreviewRow1 = () => {
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <h3 style={{ color: "white" }} id="pageTitle">
                Subscription-only
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid className="blog-page-programs">
          <Row
            style={{
              flexWrap: "nowrap",
              overflow: "visible",
              overflowX: "scroll",
              paddingLeft: 0,
            }}
          >
            <Col
              onClick={() => {
                doLogin();
              }}
              className="blogTopicCol"
              lg="3"
              sm="6"
              xs="6"
            >
              <a href="#">
                <BlogTopicCard
                  data={{
                    title: "The Mind of Mr. Well-Travelled",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL +
                      "/images/previewWellTravelled.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>

            <Col
              onClick={() => {
                doLogin();
              }}
              className="blogTopicCol"
              lg="3"
              sm="6"
              xs="6"
            >
              <a
                style={{
                  textDecoration: "",
                }}
                href="#"
              >
                <BlogTopicCard
                  data={{
                    title: "Afrofuture 2030",
                    id: "t",
                    mainImage: process.env.PUBLIC_URL + "/images/vrPreview.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>

            <Col
              onClick={() => {
                doLogin();
              }}
              className="blogTopicCol"
              lg="3"
              sm="6"
              xs="6"
            >
              <a href="#">
                <BlogTopicCard
                  data={{
                    title: "Afrofuture 2100",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL + "/images/spacePreview.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
            <Col
              onClick={() => {
                doLogin();
              }}
              className="blogTopicCol"
              lg="3"
              sm="6"
              xs="6"
            >
              <a href="#">
                <BlogTopicCard
                  data={{
                    title: "Afrofuture 2600",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL + "/images/earthPreview.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>

            <Col
              onClick={() => {
                doLogin();
              }}
              className="blogTopicCol"
              lg="3"
              sm="6"
              xs="6"
            >
              <a href="#">
                <BlogTopicCard
                  data={{
                    title: "Unity is the Future",
                    id: "t",
                    mainImage: process.env.PUBLIC_URL + "/images/Unity2b&w.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
            <Col
              onClick={() => {
                doLogin();
              }}
              className="blogTopicCol"
              lg="3"
              sm="6"
              xs="6"
            >
              <a href="#">
                <BlogTopicCard
                  data={{
                    title: "Strategic Afrofuturism",
                    id: "t",
                    mainImage: process.env.PUBLIC_URL + "/images/cur1.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const PreviewRow2 = () => {
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <h3 style={{ color: "white" }} id="pageTitle">
                Free
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid className="blog-page-programs">
          <Row
            style={{
              flexWrap: "nowrap",
              overflowY: "visible",
              overflowX: "scroll",
              paddingLeft: 0,
            }}
          >
            <Col className="blogTopicCol" lg="3" sm="6" xs="6">
              <a
                style={{
                  textDecoration: "",
                }}
                href="https://www.clubhouse.com/room/xB4ldWb4?utm_medium=ch_room_xerc&utm_campaign=lw9MCmcfKhxrf8Voj7Woog-137555"
                target={"_blank"}
              >
                <BlogTopicCard
                  data={{
                    title: "What Is Afro-Spirituality?",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL + "/images/afrospirit.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
            <Col className="blogTopicCol" lg="3" sm="6" xs="6">
              <a
                style={{
                  textDecoration: "",
                }}
                href="/afroverseCalendar"
                target={"_blank"}
              >
                <BlogTopicCard
                  data={{
                    title: "Afroverse Calendar",
                    id: "t",
                    mainImage: process.env.PUBLIC_URL + "/images/afroCal.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
            <Col className="blogTopicCol" lg="3" sm="6" xs="6">
              <a
                style={{
                  textDecoration: "",
                }}
                href="https://oncyber.io/mrwelltravelled"
                target={"_blank"}
              >
                <BlogTopicCard
                  data={{
                    title: "Well-Travelled NFT Gallery",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL + "/images/NFTGALLERY.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
            <Col className="blogTopicCol" lg="3" sm="6" xs="6">
              <a
                style={{
                  textDecoration: "",
                }}
                href="https://welltravelled.shop/"
                target={"_blank"}
              >
                <BlogTopicCard
                  data={{
                    title: "Well-Travelled Shop",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL + "/images/WellTravelShop.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
            <Col className="blogTopicCol" lg="3" sm="6" xs="6">
              <a
                style={{
                  textDecoration: "",
                }}
                href="https://www.clubhouse.com/room/xLWXE7rk?utm_medium=ch_room_xerc&utm_campaign=lw9MCmcfKhxrf8Voj7Woog-103189"
                target={"_blank"}
              >
                <BlogTopicCard
                  data={{
                    title: "The Blyss Spot",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL + "/images/Blyssvertical.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
            <Col className="blogTopicCol" lg="3" sm="6" xs="6">
              <a
                style={{
                  textDecoration: "",
                }}
                href="https://www.clubhouse.com/room/mJvyV9zV?utm_medium=ch_room_xerc&utm_campaign=lw9MCmcfKhxrf8Voj7Woog-108800"
                target={"_blank"}
              >
                <BlogTopicCard
                  data={{
                    title: "Afrofuturism on the Blockchain",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL + "/images/AotBExperience.png",
                  }}
                  id={`topic`}
                  color={"white"}
                />
              </a>
            </Col>
            <Col className="blogTopicCol" lg="3" sm="6" xs="6">
              <a
                style={{
                  textDecoration: "",
                }}
                href="https://www.clubhouse.com/room/MdRXEKzA?utm_medium=ch_room_xerc&utm_campaign=lw9MCmcfKhxrf8Voj7Woog-119422"
                target={"_blank"}
              >
                <BlogTopicCard
                  data={{
                    title: "Building a Blockchain Brand",
                    id: "t",
                    mainImage:
                      process.env.PUBLIC_URL + "/images/BlockchainCover.png",
                  }}
                  color={"white"}
                  id={`topic`}
                />
              </a>
            </Col>
            <Col className="blogTopicCol" lg="3" sm="6" xs="6">
              <a
                style={{
                  textDecoration: "",
                }}
                href="https://youtube.com/channel/UCy4iKnMWahMM9WdvXoaDePg"
                target={"_blank"}
              >
                <BlogTopicCard
                  data={{
                    title: "Community is a Verb",
                    id: "t",
                    mainImage: process.env.PUBLIC_URL + "/images/CiaV.png",
                  }}
                  color={"white"}
                  id={`topic`}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const DynamicRow = ({ data }) => {
    const { title, cards } = data;

    const cardDisplays = cards.map((card) => {
      const { title, link, isFree, image } = card;

      let imageLink =
        process.env.PUBLIC_URL + "/images/previewWellTravelled.png";
      try {
        imageLink = `https://cdn.sanity.io/images/jcvgjqzy/blog_data/${image.asset._ref
          .replace("image-", "")
          .replace(/-(png|jpg|jpeg|gif)/, ".$1")}`;
      } catch (e) {
        console.log(`Error loading image - ${e}`);
      }

      const onClicker = isFree
        ? () => {}
        : () => {
            doLogin();
          };
      return (
        <Col
          onClick={() => {
            onClicker();
          }}
          className="blogTopicCol"
          lg="4"
          sm="7"
          xs="7"
        >
          <a href={isFree ? link : "#"} target={isFree ? "_blank" : "_self"}>
            <BlogTopicCard
              data={{
                title: title,
                id: "t",
                mainImage: imageLink,
              }}
              id={`topic`}
              color={"white"}
            />
          </a>
        </Col>
      );
    });
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <h3 style={{ color: "white" }} id="pageTitle">
                {title}
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid className="blog-page-programs">
          <Row
            style={{
              flexWrap: "nowrap",
              overflow: "visible",
              overflowX: "scroll",
              paddingLeft: 0,
            }}
          >
            {cardDisplays}
          </Row>
        </Container>
      </>
    );
  };

  const DisplayPreviewSections = () => {
    if (programRows) {
      return (
        programRows
          // TODO: remove after Magazine is public
          .filter(
            (data) =>
              !(
                data.title.startsWith("Magazine") &&
                window.location.hostname !== "localhost"
              )
          )
          .map((rowData) => {
            return <DynamicRow data={rowData} />;
          })
      );
    } else {
      return null;
    }
  };
  /**
   * this handles the case when users visits the site already logged in
   */
  useEffect(() => {
    handleSignIn();
  }, [isAuthenticated]);

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      doDisplayPopUp();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Divider = () => (
    <div style={{ color: "#757575" }} className="mr-2">
      |
    </div>
  );

  useEffect(() => {
    if (error) {
      if (error.message !== "Invalid state") {
        alert("An error has occured with auth0");
      } else {
        document.getElementById("login-btn").click();
      }
      console.log(error);
    }
  }, [error]);

  return (
    <>
      <Container fluid id="home-page">
        <Row
          id="home-page-section-1"
          style={section1Hero}
          className="home-page-section"
        >
          <Col id="home-page-section-1-divider"></Col>
          <Col id="home-page-section-1-text-container">
            <img id="home-page-section-2-img-text" src={section1HeroText} />
            <p className="home-page-hero-sub-text">
              {section1SubText.map((text) => (
                <>
                  <span>{text}</span>
                  <br />
                </>
              ))}
            </p>
            <div id="home-page-hero-btn-container" className="mb-3">
              <LoginButton
                classToAdd={"dark big"}
                isSignUp={true}
                className="dark-btn"
                imageUrl={section1HeadingButton}
              />
            </div>
            <div id="home-page-hero-btn-container">
              {/*
              // TODO uncomment when video is ready
              <FontAwesomeIcon
                size={"xs"}
                icon={faPlay}
                style={{
                  backgroundColor: "black",
                  width: "50px",
                  height: "50px",
                  padding: "10px 20px 10px 20px",
                  borderRadius: 100,
                  cursor: "pointer",
                  color: "rgb(63, 237, 244)",
                  boxShadow:
                    " 2px 2px 12px 0 rgba(128, 128, 128, 0.63), -2px -2px 12px 0 rgba(155, 155, 155, 0.3)",
                }}
                className="FAQ-header-icon"
                onClick={() => {
                  setDoShowYoutubeModal(true);
                }}
              /> */}
            </div>
          </Col>
        </Row>
        <Row
          id="home-page-section-2"
          className="home-page-section home-page-sub-section"
        >
          <Col
            id="home-page-section-2-text-container "
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img id="home-page-section-2-img-text" src={section1HeadingText} />
            <p className="home-page-mid-text !mb-4">
              {section1HeadingSubText.map((text) => (
                <p>{text}</p>
              ))}
            </p>
          </Col>

          <DisplayPreviewSections />
          {/* <Col className="mt-5" id="home-page-section-2-content-container">
            {PreviewRow1()}
          </Col>
          <Col className="mt-5" id="home-page-section-2-content-container">
            {PreviewRow2()}
          </Col> */}
        </Row>
        <Row
          id="home-page-section-3"
          className="home-page-section home-page-sub-section"
        >
          <Col
            lg="6"
            id="home-page-section-3-text-container"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img id="home-page-section-3-img-text" src={section2HeadingImage} />
            <p className="home-page-mid-text !mb-4">
              {section2SubText.map((text) => (
                <>
                  <span>{text}</span>
                  <br />
                </>
              ))}
            </p>
          </Col>
          <Col lg="6" id="home-page-section-3-content-container">
            <img id="device-pile-img" src={section2SecondaryImage} />
          </Col>
        </Row>
        <Row id="faq-sec" className="home-page-section home-page-sub-section">
          <Col lg="12" id="home-page-section-3-text-container">
            <h3 className="home-page-large-text">Frequently Asked Questions</h3>
          </Col>
          <FAQS />
          <Col lg="12">
            <div id="home-page-footer-btn-container" className="mt-5">
              <LoginButton
                classToAdd={"dark big"}
                isSignUp={true}
                className="dark-btn"
                imageUrl={section1HeadingButton}
              />
            </div>
          </Col>
        </Row>
        <Row
          id="home-page-section-5"
          className="home-page-section home-page-sub-section noBorder"
        >
          <DisplayFooter footerData={footerSection} />
          {/* {#TODO fix margin} */}
          {/* <Col xs={12}>
            <p className="footer-text text-center" style={{ lineHeight: 2 }}>
              Designed to bring people together
              <br />
              by{" "}
              <a
                className="footer-link"
                target={"_blank"}
                href="https://www.instagram.com/mrwelltravelled/"
                style={{ color: "#cccc" }}
              >
                Mr. Well-Travelled
              </a>
            </p>
          </Col>
          <Col xs={12}>
            <div className="text-center" id="footer-links-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <a
                  className="footer-link mr-2"
                  target={"_blank"}
                  href="/afroverseCalendar"
                >
                  Calendar
                </a>
                <Divider />
                <a
                  className="footer-link mr-2"
                  target={"_blank"}
                  href="https://welltravelled.shop/"
                >
                  Shop
                </a>
                <Divider />
                <a
                  className="footer-link mr-2"
                  target={"_blank"}
                  href="https://oncyber.io/mrwelltravelled"
                >
                  Gallery
                </a>
                <Divider />
                <a
                  className="footer-link"
                  target={"_blank"}
                  href="https://www.clubhouse.com/club/guide-to-the-afroverse?utm_medium=ch_club&utm_campaign=lw9MCmcfKhxrf8Voj7Woog-90062"
                >
                  Clubhouse
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <p className="footer-text text-center mt-3">
              © 2022 Well-Travelled Explorations, LLC
            </p>
          </Col>
          <Col xs={12}>
            <div className="text-center" id="footer-links-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <Link to={"/contact-us"}>
                  <a className="footer-link mr-2">Contact</a>
                </Link>
                <Divider />
                <a
                  href="https://www.termsfeed.com/live/6b6a9fdf-8305-43d8-829c-5122e18b0f50"
                  target={"_blank"}
                  className="footer-link mr-2"
                >
                  Privacy
                </a>
                <Divider />
                <a
                  href="https://www.termsfeed.com/live/33f3f5fc-1a1b-46ba-9f0a-9742272079e2"
                  target={"_blank"}
                  className="footer-link mr-2"
                >
                  Cookies
                </a>
                <Divider />

                <a
                  href="https://www.termsfeed.com/live/f82278e8-10fd-4686-a43a-6d0b13edb562"
                  target={"_blank"}
                  className="footer-link mr-2"
                >
                  Terms
                </a>

                <Divider />
                <a
                  href="https://www.termsfeed.com/live/d3bfdac0-e963-4ade-bb61-40c061622dea"
                  target={"_blank"}
                  className="footer-link mr-2"
                >
                  Disclaimer
                </a>
              </div>
            </div>
          </Col> */}
        </Row>
        <SignUpModal doShow={doShowModal} />
        <YoutubeModal
          doShow={doShowYoutubeModal}
          setShow={setDoShowYoutubeModal}
        />
      </Container>
      {/* pre-page load fade in comp */ AppCover()}
    </>
  );
};
