import { useContext, useState, useEffect } from "react";
import { globalFetch } from "../../utils";
import { UserContext } from "../../context";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import { Route } from "react-router-dom";

export const ProtectedComp = ({ children, path, exact = false }) => {
  const userContext = useContext(UserContext);

  const [isAnimationDone, setIsAnimationDone] = useState(false);

  const history = useHistory();

  const { isLoading, isAuthenticated, error, user } = useAuth0();

  const getUserData = async (user) => {
    const { data } = await globalFetch(`/api/user/${user.email}`);
    return data;
  };

  const handleAuthentication = async (animationDur = 1000) => {
    if (isAuthenticated) {
      if (!userContext.userData) {
        try {
          const data = await getUserData(user);
          await userContext.setUserData(data);
          const { isAccountSetupCompleted, paymentType } = data;
          // user needs to complete their account set up or crypto payment is loading
          if (!isAccountSetupCompleted) {
            if (paymentType === "coinbase") {
              history.push("/payment-auth");
            } else {
              history.push("/signup/registration");
            }
            return;
          }
        } catch (e) {
          console.log(e);
          alert("something happened when fetching the user");
          //TODO should i log out?
          return;
        }
      } else {
        const { isAccountSetupCompleted, paymentType } = userContext.userData;
        // user needs to complete their account set up or crypto payment is loading
        if (!isAccountSetupCompleted) {
          if (paymentType === "coinbase") {
            history.push("/payment-auth");
          } else {
            history.push("/signup/registration");
          }
          return;
        }
      }
      setTimeout(() => {
        setIsAnimationDone(true);
      }, animationDur);
    } else {
      //need to re-login
      if (!isLoading) {
        history.push("/");
        return;
      }
    }
  };

  const AppCover = () => {
    return (
      !isAnimationDone && (
        <div id="appLoadCover" className={`${isLoading ? "" : "loaded"}`}></div>
      )
    );
  };

  /**
   * this handles the case when users visits the site already logged in
   */
  useEffect(() => {
    handleAuthentication();
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (error) {
      alert("An error has occured with auth0");
      console.log(error);
    }
  }, [error]);

  return (
    <Route path={path} exact={exact}>
      {children}
      {/* pre-page load fade in comp */ AppCover()}
    </Route>
  );
};
