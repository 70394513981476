import Router, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LoaderSmall } from "../../components";
import { UserContext } from "../../context";

import {
  createLog,
  getCookie,
  getIpLocation,
  setCookie,
  translateMap,
  globalFetch,
} from "../../utils";
export const PaymentAuth = () => {
  const userContext = useContext(UserContext);

  const [selectedLang, setSelectedLang] = useState("EN");

  const [setIsLoading] = useState(true);

  const history = useHistory();

  const [loadingMessage, setLoadingMessage] = useState(
    "Authorizing Crypto Payment. Please wait..."
  );

  const handleClientLang = async () => {
    const cookieLang = await getCookie("explorations-lang");
    if (cookieLang) {
      setSelectedLang(cookieLang);
      console.log(`Lang found in cookies ${cookieLang}`);
    } else {
      try {
        const { country_code } = await getIpLocation();
        if (translateMap["PT"].countryCodes.includes(country_code)) {
          setSelectedLang("PT");
          await setCookie("explorations-lang", "PT");
        } else {
          await setCookie("explorations-lang", "EN");
        }
      } catch (e) {
        console.log(`Error setting lang - ${e}`);
      }
      setIsLoading(false);
    }
  };

  const handleLangChange = (e) => {
    const oldLang = selectedLang;
    const newLang = e;
    setSelectedLang(newLang);
    setCookie("explorations-lang", newLang);
    try {
      createLog(
        null,
        "COOKIES SET",
        `Language updated from ${oldLang} to ${newLang}`
      );
    } catch (e) {
      console.log(`Could not set cookies - ${e}`);
    }
  };

  const doFetchCryptoPaymentStatus = async () => {
    if (!userContext || !userContext.userData) {
      // redirect to login
      window.location.href = "/";
    } else {
      // check if their user data has 'isCoinbasePaymentCompleted' true
      const { data } = await globalFetch(
        `/api/user/${userContext.userData.email}`
      );
      if (data.isCoinbasePaymentCompleted && data.isAccountSetupCompleted) {
        setLoadingMessage("Payment Successful! Logging in");
        setTimeout(() => {
          window.location.href = "/programs";
        }, 2000);
      }
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      doFetchCryptoPaymentStatus();
    }, 15000);
    return () => {
      clearTimeout(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="container-fluid App"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        background:
          "linear-gradient(135deg, rgba(131,255,243,1) 0%, rgba(64,224,208,1) 50%, rgba(62,152,143,1) 100%)",
        overflow: "hidden",
      }}
    >
      <Row
        style={{
          height: "100%",
          alignItems: "center",
          position: "relative",
          top: "-50px",
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              height={"90%"}
              width={"90%"}
              id="maintainaceImg"
              src={process.env.PUBLIC_URL + "/images/image.png"}
            />
          </div>
          <LoaderSmall text={loadingMessage} />
        </Col>
      </Row>
    </div>
  );
};
