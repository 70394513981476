import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { globalFetch } from "../../utils";
import "./style.css";

export const LegalNoticesLega = () => {
  return (
    <Container id="policy-page">
      <Row>
        <Col>
          <h1>Legal Notices</h1>
          <p>Last updated: October 21, 2020</p>
          <p>
            All content on{" "}
            <a href="https://mrwelltravelled.com" target={"_blank"}>
              mrwelltravelled.com
            </a>{" "}
            is protected by copyright, trademark, trade secret or other
            intellectual property laws and treaties.
          </p>
          <h3>Copyright</h3>
          <p>
            The copyrights in the programs and posts on the site are owned by
            Well-Travelled Explorations, LLC or an affiliated content creator.
            If you believe your or someone else’s copyrights are being infringed
            upon, let us know <a href="/contact-us">here</a>.
          </p>
          <h3>Trademarks</h3>
          <p>
            Mr. Well-Travelled and the W logo icon are trademarks of
            Well-Travelled Explorations, LLC.
          </p>
          <p>
            If you haven’t received written permission, do not use the Mr.
            Well-Travelled marks as your own or in any manner that implies
            sponsorship or endorsement by Mr. Well-Travelled or Well-Travelled
            Explorations, LLC.
          </p>
          <p>
            Any physical or digital product branded with the Mr. Well-Travelled
            name, logo, or logo icon is a reflection of Mr. Well-Travelled.
            Well-Travelled Explorations, LLC does not allow others to make,
            sell, or give away anything with the Mr. Well-Travelled name, logo,
            or logo icon on it.
          </p>
          <h3>Third Party Notices</h3>
          <p>
            The software integrations on{" "}
            <a href="https://mrwelltravelled.com">mrwelltravelled.com</a> that
            enable the site to function as a subscription entertainment service
            may contain software available under open source or free software
            and/or commercial software licensed to Well-Travelled Explorations,
            LLC by third parties. The{" "}
            <Link to={"/terms"}>mrwelltravelled.com Terms of Use</Link> do not
            alter any rights or obligations you may have under those open source
            software licenses.
          </p>
          <h4>Last Updated: January 17, 2022</h4>
        </Col>
      </Row>
    </Container>
  );
};

const Content = ({ data }) => {
  const { children, style, markDefs, _type, asset, listItem } = data;
  console.log(children);
  const TagWrappedContent = (content) => {
    if (style === "h1") {
      return <h1>{content}</h1>;
    } else if (style === "h2") {
      return <h2>{content}</h2>;
    } else if (style === "h3") {
      return <h3>{content}</h3>;
    } else if (style === "h4") {
      return <h4>{content}</h4>;
    } else {
      return (
        <p style={{ fontSize: "1.7rem", lineHeight: "40px" }}>{content}</p>
      );
    }
  };

  const MapContent = () => {
    if (children) {
      return children.map((section, idx) => {
        const { marks, text } = section;
        const classNames = [];
        if (marks.includes("strong")) {
          classNames.push("boldSection");
        }
        if (marks.includes("em")) {
          classNames.push("italicSection");
        }
        const otherMarks = marks.filter((md) => {
          return md !== "em" && md !== "strong";
        });

        if (listItem && listItem === "bullet") {
          return <li>{text}</li>;
        }

        if (otherMarks.length > 0) {
          const [targetMarkDef] = markDefs.filter((md) => {
            return md._key === otherMarks[0];
          });
          return (
            <a
              href={targetMarkDef.href}
              key={`sec-${idx}`}
              className={classNames}
              target={"_blank"}
            >
              {text}
            </a>
          );
        }
        return (
          <span key={`sec-${idx}`} className={`${classNames}`}>
            {text}
          </span>
        );
      });
    }
    return null;
  };

  if (_type !== "image") {
    return (
      <div style={{ marginBottom: "10px", minHeight: 30 }}>
        {TagWrappedContent(<> {MapContent()}</>)}
      </div>
    );
  }
  if (_type === "image") {
    return (
      <div style={{ marginBottom: "50px" }}>
        <img
          src={`https://cdn.sanity.io/images/jcvgjqzy/blog_data/${asset._ref
            .replace("image-", "")
            .replace(/-(png|jpg|jpeg)/, ".$1")}`}
        ></img>
      </div>
    );
  }

  return null;
};

export const LegalNotices = () => {
  const [pageContent, setPageContent] = useState(null);

  const getIframeScale = () => {
    const clinetWidth = window.innerWidth;
    if (clinetWidth > 1024) {
      return {
        width: "2000",
        height: "600",
      };
    }
    if (clinetWidth > 767) {
      return {
        width: "500",
        height: "300",
      };
    }
    return {
      width: "500",
      height: "200",
    };
  };

  const [iFrameScale] = useState(getIframeScale());

  const fetchContet = async () => {
    try {
      const { data } = await globalFetch(`/api/pages/legalNotice`, "GET");
      setPageContent({
        title: data?.title ? data.title : "No Title",
        body: data?.body ? data.body : "No content available",
      });
    } catch (e) {
      setPageContent({ title: "Error", body: "Error Fetching Content" });
      console.log(e);
    }
  };

  const MapBlogPostBody = () => {
    return pageContent.body.map((section, idx) => {
      return <Content key={`content-${idx}`} data={section} />;
    });
  };

  useEffect(() => {
    fetchContet();
  }, []);

  return (
    <Container fluid id="blogPost-page" style={{ marginTop: "150px" }}>
      <Row>
        <Col>
          <div id="blogPostTitleContainer">
            <h2 id="blogPostTitle">{pageContent?.title}</h2>
            <div id="blogPostTitleDividerContainer">
              {/* <div id="blogPostTitleDivider"></div> */}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="blogPostContentContainer">
            {pageContent?.body ? <MapBlogPostBody /> : ""}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
