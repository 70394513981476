import { Modal } from "react-bootstrap";
import styled from "styled-components";
import "./style.css";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
`;
export const YoutubeModal = ({ doShow = false, setShow }) => {
  return (
    <>
      <Modal
        show={doShow}
        backdrop="static"
        size="lg"
        onHide={() => {
          setShow(false);
        }}
        backdropClassName="warning"
        style={{ marginTop: 100 }}
      >
        <div id="YoutubeModal">
          <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
          <Modal.Body>
            <div>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/W7WLC2Re61c?autoplay=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                autoplay="true"
              ></iframe>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {doShow && <Backdrop />}
    </>
  );
};
