import { translateMap } from "../../utils";
import "./style.css";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";

const LangSelect = ({
  setSelectedLang,
  selectedLang,
  classToAdd = "light",
}) => {
  const [bgcolor, setBgColor] = useState("info");

  const doDisplayNav = () => {
    let scrolled = document.scrollingElement.scrollTop;
    if (scrolled > 70) {
      setBgColor("danger");
    } else {
      setBgColor("info");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      doDisplayNav();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownButton
      title={translateMap[selectedLang].name}
      id={
        classToAdd === "light"
          ? "dropdown-menu-align-end-light"
          : "dropdown-menu-align-end-dark"
      }
      className={"light"}
    >
      {Reflect.ownKeys(translateMap).map((langKey, idx) => (
        <Dropdown.Item
          eventKey="1"
          onClick={(e) => {
            setSelectedLang(e.target.id);
          }}
          id={langKey}
          key={`idx-${idx}`}
        >
          {translateMap[langKey].name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

LangSelect.propTypes = {};

export default LangSelect;
