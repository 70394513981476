import { useState, useEffect } from "react";

export const VimeoPage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`/api/vimeo-page/${window.location.pathname.split("/").pop()}`)
      .then((res) => res.json())
      .then(setData);
  }, []);

  if (!data) return <div className="bg-black min-h-screen" />;

  if (data.video === null) {
    return (
      <div className="using-tailwind bg-black min-h-screen px-4 pt-24 sm:pt-36 pb-48">
        <p className="text-gray-400 font-medium text-center text-xl">
          Video not found
        </p>
      </div>
    );
  }

  return (
    <div className="using-tailwind bg-black h-screen px-4 pt-24 sm:pt-36 pb-4 grid grid-rows-[max-content,1fr] gap-y-4">
      <p className="text-white text-3xl sm:text-4xl font-bold max-w-screen-sm mx-auto">
        {data.video.title}
      </p>
      <iframe
        title={data.video.title}
        src={`https://player.vimeo.com/video/${data.video.vimeoUrl
          .split("/")
          .pop()}`}
        className="w-full h-full"
      />
    </div>
  );
};
