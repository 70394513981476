import { useContext, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { DisplayLocalText, Loader1, PageTitle } from "../../components";
import { globalFetch } from "../../utils";
import { Error404 } from "../Error/404";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { LangContext } from "../../context";

const Content = ({ data }) => {
  const { children, style, markDefs, _type, asset } = data;

  const TagWrappedContent = (content) => {
    if (style === "h1") {
      return <h1>{content}</h1>;
    } else if (style === "h2") {
      return <h2>{content}</h2>;
    } else if (style === "h3") {
      return <h3>{content}</h3>;
    } else if (style === "h4") {
      return <h4>{content}</h4>;
    } else {
      return (
        <p style={{ fontSize: "1.7rem", lineHeight: "40px" }}>{content}</p>
      );
    }
  };

  const MapContent = () => {
    if (children) {
      return children.map((section, idx) => {
        const { marks, text } = section;
        const classNames = [];
        if (marks.includes("strong")) {
          classNames.push("boldSection");
        }
        if (marks.includes("em")) {
          classNames.push("italicSection");
        }
        const otherMarks = marks.filter((md) => {
          return md !== "em" && md !== "strong";
        });

        if (otherMarks.length > 0) {
          const [targetMarkDef] = markDefs.filter((md) => {
            return md._key === otherMarks[0];
          });
          return (
            <a
              href={targetMarkDef.href}
              key={`sec-${idx}`}
              className={classNames}
              target={"_blank"}
            >
              {text}
            </a>
          );
        }
        return (
          <span key={`sec-${idx}`} className={classNames}>
            {text}
          </span>
        );
      });
    }
    return null;
  };

  if (_type !== "image") {
    return (
      <div style={{ marginBottom: "50px" }}>
        {TagWrappedContent(<> {MapContent()}</>)}
      </div>
    );
  }
  if (_type === "image") {
    return (
      <div style={{ marginBottom: "50px" }}>
        <img
          src={`https://cdn.sanity.io/images/jcvgjqzy/blog_data/${asset._ref
            .replace("image-", "")
            .replace(/-(png|jpg|jpeg)/, ".$1")}`}
        ></img>
      </div>
    );
  }

  return null;
};

export const BlogPost = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [blogPostData, setBlogPostData] = useState(null);
  const { blogPostId } = useParams();
  const history = useHistory();
  const selectedLang = useContext(LangContext);

  const getIframeScale = () => {
    const clinetWidth = window.innerWidth;
    if (clinetWidth > 1024) {
      return {
        width: "2000",
        height: "600",
      };
    }
    if (clinetWidth > 767) {
      return {
        width: "500",
        height: "300",
      };
    }
    return {
      width: "500",
      height: "200",
    };
  };
  const [iFrameScale] = useState(getIframeScale());

  const fetchBlogPost = async () => {
    if (blogPostId) {
      try {
        const { data } = await globalFetch(`/api/blogPost/${blogPostId}`);

        if (data.length > 0) setBlogPostData(data[0]);
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      } catch (e) {
        alert("Error fetching blog topics");
        console.log(`Error fetching blog topics ${e}`);
        history.push(`/`);
      }
    }
  };

  const MapBlogPostBody = () => {
    if (
      selectedLang !== "EN" &&
      blogPostData.hasOwnProperty(`body${selectedLang}`)
    ) {
      return blogPostData[`body${selectedLang}`].map((section, idx) => {
        return <Content key={`content-${idx}`} data={section} />;
      });
    }
    return blogPostData.body.map((section, idx) => {
      return <Content key={`content-${idx}`} data={section} />;
    });
  };

  const doDisplayVideo = () => {
    if (blogPostData && blogPostData.videoUrl) {
      try {
        const videoUrl = blogPostData.videoUrl;
        const iFrameLink = videoUrl.includes("vimeo")
          ? `https://player.vimeo.com/video/${
              videoUrl.match(/vimeo.com\/(.*)/)[1]
            }?h=e0ccabbb67`
          : `https://www.youtube.com/embed/${
              videoUrl.match(/youtu.be\/(.*)/)[1]
            }`;
        return (
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <iframe
                src={iFrameLink}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                width={iFrameScale.width}
                height={iFrameScale.height}
                title="blogPostVideo"
                id="blogPostVideo"
              ></iframe>
            </Col>
          </Row>
        );
      } catch (e) {
        return (
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <h5>Error Loading Video :(</h5>
            </Col>
          </Row>
        );
      }
    }
    return null;
  };

  useEffect(() => {
    fetchBlogPost();
  }, []);

  return (
    <>
      {!isLoading ? (
        <>
          {blogPostData ? (
            <>
              <div style={{ display: "flex" }}>
                <PageTitle
                  prevTitle={
                    <FontAwesomeIcon
                      size={"lg"}
                      style={{ color: "salmon" }}
                      icon={faChevronLeft}
                      id="backBtn"
                    />
                  }
                  title=""
                  prevLink={`/programs/${blogPostData.blogTopic._ref}`}
                />
              </div>
              <Container fluid id="blogPost-page">
                <Row>
                  <Col>
                    <div id="blogPostTitleContainer">
                      <h2 id="blogPostTitle">
                        <DisplayLocalText
                          data={blogPostData}
                          targetDataKey={"title"}
                        />
                      </h2>
                      <div id="blogPostTitleDividerContainer">
                        <div id="blogPostTitleDivider"></div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {doDisplayVideo()}
                <Row
                  style={{
                    marginTop: blogPostData.videoUrl ? "100px" : "",
                  }}
                >
                  <Col>
                    <div id="blogPostContentContainer">
                      <MapBlogPostBody />
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <Error404 redirect={false} />
          )}
        </>
      ) : (
        <Loader1 />
      )}
    </>
  );
};
