import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "./style.css";
import { UserContext } from "../../context/";
import { getIp, globalFetch } from "../../utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm, CoinbaseCheckoutForm } from "../../components";
const liveKey =
  "pk_live_51JzPo6DVi72vpfy3eAkM01zVubzAROOcNyMqoQGlrd3Vza26KsRccu6oGVwOkArXX4kzb3fpjifoAAtk9fw0Wr3500Fiit797R";
const devKey =
  "pk_test_51JzPo6DVi72vpfy3KGO2KAGVaycD2PFCrIdCaj3K8qvZEzC0uq6C1ZVVdZmS69uLCIAH12EoEVGauzO9G45JkU0Y00OcXYeoWZ";
const stripePromise = loadStripe(liveKey);

const NextBtn = ({ doSetStep, disabled = false, label = "Next" }) => {
  return (
    <Row style={{ marginTop: "50px" }}>
      <Col>
        <Button
          style={{ width: "100%" }}
          onClick={() => {
            doSetStep();
          }}
          disabled={disabled}
        >
          {label}
        </Button>
      </Col>
    </Row>
  );
};

/**
 * entering their basic information
 * @param {*} param0
 * @returns
 */
const Step1 = ({ doSetStep, userContext, patchUser }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [disabled, setDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const doUpdateUser = async () => {
    try {
      setDisable(true);
      await patchUser(userContext.userData, { firstName, lastName });
      doSetStep();
    } catch (e) {
      alert("Error updating name");
      setDisable(false);
    }
  };

  useEffect(() => {
    //TODO need validation for first and last name
    if (errorMessage) {
      setErrorMessage("");
    }
  }, [firstName, lastName, userContext]);

  useEffect(() => {
    if (userContext.userData) {
      setFirstName(userContext.userData.firstName);
      setLastName(userContext.userData.lastName);
    }
  }, [userContext]);

  const clearFormState = () => {
    setFirstName("");
    setLastName("");
    setErrorMessage("");
    setDisable(false);
  };

  return (
    <>
      <Row className="stepInstructions">
        <Col>
          <p className="step-count">STEP 1 of 3</p>
          <h1>Start account set up</h1>
          <h3>What is your name?</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="sign-up-name-form">
            <Form>
              <Form.Group controlId="error">
                <Form.Text className="text-danger text-center">
                  {errorMessage}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="firstName">
                {/* <Form.Label>Email address or username</Form.Label> */}
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  disabled={disabled}
                  required
                  defaultValue={firstName}
                />
              </Form.Group>

              <Form.Group controlId="lastName">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  disabled={disabled}
                  required
                  defaultValue={lastName}
                />
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
      <NextBtn doSetStep={doUpdateUser} disabled={!firstName || !lastName} />
    </>
  );
};

/**
 * for selecting what pricing they want
 *
 * @param {*} param0
 * @returns
 */
const Step2 = ({ priceSelection, doSetPriceSelection, doSetStep }) => {
  return (
    <>
      <Row className="stepInstructions">
        <Col>
          <p className="step-count">STEP 2 of 3</p>
          <h1>Choose the payment method that’s right for you</h1>
          <h3 className="mb-3 mt-3">
            $5.42 per month, billed annually ($65 total)
          </h3>
        </Col>
      </Row>
      <Row>
        <Col
          xs="6"
          id="strip"
          className={`price-container ${
            priceSelection === "stripe" && "selectedPrice"
          }`}
          onClick={() => {
            doSetPriceSelection("stripe");
          }}
        >
          <img
            className="priceType-image"
            src={process.env.PUBLIC_URL + "/images/stripeLogo.png"}
          />
          <p id="stripe-text" className="pricing-text">
            Credit or debit card.
          </p>
        </Col>
        <Col
          xs="6"
          onClick={() => {
            doSetPriceSelection("coinbase");
          }}
          className={`price-container ${
            priceSelection === "coinbase" && "selectedPrice"
          }`}
        >
          <img
            className="priceType-image"
            src={process.env.PUBLIC_URL + "/images/coinbaseLogo.png"}
          />
          <p id="coinbase-text" className="pricing-text ">
            Bitcoin, Ethereum, or USDC.
          </p>
        </Col>
      </Row>
      <NextBtn doSetStep={doSetStep} disabled={priceSelection === ""} />
    </>
  );
};

/**
 * for paying for their account
 *
 * @returns
 */
const Step3 = ({ priceSelection, patchUser, userData, doSetStep }) => {
  // may not need because of the sign up

  const [clientSecret, setClientSecret] = useState("");
  const history = useHistory();

  //TODO use for both payments or create util
  const doUpdateUser = async () => {
    try {
      await patchUser(userData, {
        isAccountSetupCompleted: true,
        paymentType: "stripe",
      });
      history.push("/programs");
    } catch (e) {
      alert("Error updating isAccountSetupCompleted");
    }
  };

  const getStripeStuff = async () => {
    // Create PaymentIntent as soon as the page loads
    if (userData) {
      const { email } = userData;
      try {
        const { data } = await globalFetch(
          "/api/stripe/create-payment-intent",
          "POST",
          { customer: email, receipt_email: email }
        );
        setClientSecret(data.clientSecret);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (priceSelection === "stripe") {
      getStripeStuff();
    }
  }, [priceSelection]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Row className="stepInstructions">
        <Col>
          <p className="step-count">STEP 3 of 3</p>
          <h1>Checkout</h1>
          <h3>Mr. Well-Travelled Subscription</h3>
          <h3>$5.42 per month, billed annually ($65 total)</h3>
        </Col>
      </Row>
      <Row>
        {priceSelection === "stripe" ? (
          <>
            <Col sm={12} style={{ textAlign: "center" }}>
              <img
                className="priceType-image"
                src={process.env.PUBLIC_URL + "/images/stripeLogo.png"}
              />
            </Col>
            <Col>
              {clientSecret && userData && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    userData={userData}
                    doUpdateUser={doUpdateUser}
                  />
                </Elements>
              )}
            </Col>
          </>
        ) : (
          <>
            <Col sm={12} style={{ textAlign: "center" }}>
              <img
                className="priceType-image"
                src={process.env.PUBLIC_URL + "/images/coinbaseLogo.png"}
              />
            </Col>
            <CoinbaseCheckoutForm userData={userData} />
          </>
        )}
      </Row>
      <NextBtn
        doSetStep={() => {
          doSetStep(false);
        }}
        label={"Choose different payment method"}
      />
    </>
  );
};

//TODO use protectedComp
export const SignUp = () => {
  const [step, setStep] = useState(1);

  const [priceSelection, setPriceSelection] = useState("");

  const userContext = useContext(UserContext);

  const [isAnimationDone, setIsAnimationDone] = useState(false);

  const history = useHistory();

  const { isLoading, isAuthenticated, error, user } = useAuth0();

  const getUserData = async (user) => {
    const { data } = await globalFetch(`/api/user/${user.email}`);
    return data;
  };

  const patchUser = async (user, body) => {
    const ipAddress = await getIp();
    body = { ...body, lastIpAddress: ipAddress };
    try {
      await globalFetch(`/api/user/${user.email}`, "PATCH", body);

      const newUserData = { ...userContext.userData, ...body };
      userContext.setUserData(newUserData);
    } catch (e) {
      alert("Error updating user");
      return;
    }
  };

  const handleSignIn = async (animationDur = 900) => {
    if (isAuthenticated) {
      if (!userContext.userData) {
        try {
          const data = await getUserData(user);
          await userContext.setUserData(data);
        } catch (e) {
          console.log(e);
          alert("something happened when fetching the user");
          history.push("/");
          return;
        }
      }

      setTimeout(() => {
        setIsAnimationDone(true);
      }, animationDur);
    } else {
      if (!isLoading) {
        history.push("/");
      }
    }
  };

  const AppCover = () => {
    return (
      (!isAnimationDone || !userContext.userData) && (
        <div id="appLoadCover" className={`${isLoading ? "" : "loaded"}`}></div>
      )
    );
  };

  const doSetStep = (forward = true) => {
    if (forward) {
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            doSetStep={doSetStep}
            patchUser={patchUser}
            userContext={userContext}
          />
        );
      case 2:
        return (
          <Step2
            doSetPriceSelection={setPriceSelection}
            priceSelection={priceSelection}
            doSetStep={doSetStep}
            patchUser={patchUser}
            userContext={userContext}
          />
        );
      case 3:
        return (
          <Step3
            priceSelection={priceSelection}
            doSetStep={doSetStep}
            patchUser={patchUser}
            userData={userContext.userData}
          />
        );

      default:
        return null;
    }
  };

  /**
   * this handles the case when users visits the site already logged in
   */
  useEffect(() => {
    handleSignIn();
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (error) {
      alert("An error has occured with auth0");
      console.log(error);
    }
  }, [error]);

  return (
    <>
      {AppCover()}
      <Container id="signup-page">{renderStep()}</Container>
    </>
  );
};
